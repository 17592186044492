.seminare-boxen {
  display: flex;
  flex-direction: row;
  position: relative;
  .seminar-box {
    width: calc(100% / 3 - 20px);
    margin: 10px;
    position: relative;
    .seminar-teaser {
      position: relative;
      width: 100%;
      box-shadow: 0px 0px 10px rgba(100,100,100,0.5);
      margin-bottom: 20px;
      picture {
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .seminar-headline {
      position: relative;
      width: 100%;
      text-align: center;
      font-size: 30px;
      background-size: cover !important;
    }
    .seminar-subheadline {
      text-align: center;
    }
    .seminar-programmdauer {
      text-align: center;
    }
  }
}