@include media-breakpoint-down(lg) {
  @include media-breakpoint-up(sm) {
    html {
      #teaserBilder {
        .teaser--bg {
          top: 100px !important;
        }
      }
      body {
        header {
          #header-icon {
            width: 135px;
            a {
              margin-right: 10px;
              i {
                img,svg {
                  height: 30px;
                }
              }
            }
            a:last-child {
              margin-right: 0px !important;
            }
          }
        }
        h1 {
          font-size: 40px !important;
        }
        h2 {
          font-size: 22px !important;
        }
        h3 {
          font-size: 20px !important;
        }
        #navigation {
          height: 130px;
          canvas {
            margin-top: 20px;
          }
        }
        main {
          #teaVideo {
            min-height: calc(100vw / 16 * 9 - 150px) !important;
          }
        }
      }
    }
  }
  .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5,.col-md-6, .col-md-7,.col-md-8,.col-md-9,.col-md-10,.col-md-11,.col-md-12 {
    padding-right: 5px !important;
    padding-left: 5px !important;
  }

  .pb-4 {
    padding-bottom: 10px !important;
  }

  body {
    font-size: 1rem !important;
    header {
      .headerlogo {
        picture {
          img {
            max-height: 160px !important;
          }
        }
      }
      .logo-left {
        height: 40px !important;
        z-index: 9999;
        img {
          max-width: 50px !important;
        }
      }
      .logo-right {
        z-index: 9999;
        img,svg {
          height: 40px !important;
        }
      }
    }
    main {
      padding-top: 130px !important;
      .teaser {
        article {
          h1 {
            font-size: 50px !important;
          }
        }
      }
      .grid-shadow-left .shd-box:before {
        box-shadow: 0 15px 25px rgba(100,100,100,.8);
        width: 110%;
      }
      .parallax-bilder {
        height: 290px !important;
        min-height: 290px !important;
        picture {
          img {
            width: unset !important;
            min-width: 100% !important;
            position: absolute;
          }
        }
      }
      .infobox {
        margin-left: -20px !important;
        margin-right: -20px !important;
      }
      .parallax-bilder-fixed {
        picture {
          img {
            min-width: 100% !important;
            min-height: 100% !important;
          }
        }
      }
      .tinyMCEbackground-3 {
        padding: 15px;
        p {
          font-size: 15px !important;
        }
      }
      hr {
        margin-top: 5px;
      }
      h4 {
        font-size: 22px !important;
      }
      .buttons-modul .btn-modul i {
        width: 90px;
        height: 90px;
        padding: 15px !important;
      }
    }
    footer {
      width: 100% !important;
      min-height: 400px !important;
      .ilu {
        z-index: 1;
      }
      .grid-container{
        z-index: 999;
      }

      .kontakticons {
        a {
          .fontawsome-icon {
            width: 70px;
          }
          i {
            padding: 0px 10px 10px;
            img,svg {
              height: 50px;
            }
          }
        }
      }
    }
  }
}