.cat-listing {
  margin-top: 10px;
  margin-left: -15px !important;
  margin-right: -15px !important;
  margin-bottom: 40px;
  width: calc(100% + 30px) !important;
  justify-content: center;
  div {
    a {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 25px 10px;
      background: #fff;
      color: $ci-color-1;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.2);
      margin-bottom: 20px;
      text-align: center;
      transition: all 0.3s;
      font-size: 1.4rem;
      align-items: center;
      min-height: 270px;
      justify-content: flex-start;
      .cat-listing-headline {
        min-height: 68px;
      }
      .cat-listing-text {
        font-size: 14px;
        min-height: 85px;
      }
      .cat-listing-devider {
        width: 100%;
        hr {
          width: 100%;
        }
        .fontawsome-icon {
          img,svg {
            fill: #a69d8a;
            color: #a69d8a;
            width: 30px;
          }
        }
      }
    }
    a:hover {
      color: $ci-color-1;
      opacity: 0.7;
    }
  }
}

@include media-breakpoint-down(md) {
  .cat-listing {
    margin-top: 10px;
    margin-bottom: 40px;
    margin-left: 10px !important;
    width: calc(100% - 20px) !important;
  }

}