$ci-color-1: #ff0000 !default;
$ci-color-2: #ff0000 !default;
$ci-color-3: #ff0000 !default;
$ci-color-4: #ff0000 !default;
$ci-color-5: #ff0000 !default;
.modul-boxen {
  .row {
    justify-content: center;
  }
  .modul-box {
    margin-bottom: 30px;
    padding: 20px;
    h3 {
      max-width: 90%;
      margin-left: auto;
      margin-right: auto;
      border-top: 1px solid #999;
      background-size: cover !important;
      width: 100%;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      font-weight: 400;
      font-size: 20px;
      color: #7e1213;
      text-shadow: none;
      margin-bottom: 0px;
      min-height: 60px;
    }
    .headline-1 {
      background: url('../../../media/wood_1.png') $ci-color-1;
    }
    .headline-2 {
      background: url('../../../media/wood_2.png') $ci-color-2;
    }
    .headline-3 {
      background: url('../../../media/wood_3.png') $ci-color-3;
    }
    .headline-4{
      background: url('../../../media/wood_4.png') $ci-color-4;
    }
    .headline-5 {
      background: url('../../../media/wood_5.png') $ci-color-5;
    }
    .job-bild {
      width: 100%;
      height: auto;
      position: relative;
      picture {
        width: 100%;
        height: 0px;
        position: relative;
        img {
          width: 100%;
          height: auto;
          aspect-ratio: 1 / 1;
        }
      }
    }
    .beschreibung {
      padding: 10px 10px 0px 10px;
      min-height: 150px;
    }
    .box-infos {
      width: 100%;
      height: auto;
      padding: 5px;
      background: $ci-color-1;
      position: relative;
      color: #fff;
      i {
        position: absolute;
        right: 12px;
        img,svg {
          padding: 5px;
          width: 30px;
        }
      }
    }
    .box-infos-1 { background: $ci-color-1; }
    .box-infos-2 { background: $ci-color-2; }
    .box-infos-3 { background: $ci-color-3; }
    .box-infos-4 { background: $ci-color-4; }
    .box-infos-5 { background: $ci-color-5; }
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
  }
}