$shortcut-icon-fill: #ff0000 !default;
$shortcut-font-size: 16px !default;
$shortcut-font-weight: 200 !default;
.shortcuts {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .shortcut {
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      opacity: 1;
      transition: 0.3s;
    }
    a:hover {
      opacity: 0.75;
    }
    h6 {
      color: $shortcut-icon-fill;
      text-align: center;
      font-size: $shortcut-font-size;
      font-weight: $shortcut-font-weight;
      margin-top: 10px;
    }
    .shortcut--icon {
      width: 100px;
      height: 100px;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      svg {
        color: $shortcut-icon-fill;

        .st0 {
          fill: $shortcut-icon-fill;
        }
      }
    }
  }
}