.kalender-startseite {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  margin: 60px 0 20px;
  width: 100%;
  background: #fff;
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px;
  .kalender-item {
    width: 100%;
    position: relative;

    padding-bottom: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0,0,0,.1);
  }
  .kalender-item:nth-last-child(1) {
    border-bottom: none;
  }
  .kalender--datum {
    font-size: 18px !important;
    font-family: "PT Sans";
    text-shadow: none !important;
    color: #fff !important;
    height: 25px;
    font-weight: 600;
    background: #9bb600!important;
    text-align: center;
  }
  .kalender--titel {
    font-size: 18px !important;
    margin-top: 5px;
    font-family: "PT Sans";
    text-shadow: none !important;
    color: #0886c3 !important;
    text-align: center;
  }
  .kalender--text {
    color: #333333;
    overflow: hidden;
    position: relative;
    height: auto;
    max-height: 49px;
    .kalender-text-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(255,255,255,0) 32%, rgba(255,255,255,1) 92%);
    }
    .kalender--text-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(255,255,255,0) 32%, rgba(255,255,255,1) 92%);
    }
  }
  hr {
    height: 1px !important;
    background: rgba(255,255,255,0.2) !important;
    border-top: 1px solid rgba(0,0,0,0.2) !important;
    width: 343px;
    transform: rotate(-1deg);
    margin-top: 0px;
    position: absolute;
    bottom: 73px;
  }
  .kalender--icon {
    position: absolute;
    width: 50px;
    right: 20px;
    bottom: 66px;
    svg {
      fill: #006d45;
    }
  }
}

#newsbilder {
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px;
  .newsbilder-bild {
    width: 100%;
    height: 100%;
    picture {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

$simplecalender-header-bg: #ff0000 !default;
$simplecalender-hasdate-bg: #00ff00 !default;
.kalender-uebersicht {
  max-width: 422px;
  position: relative;
  padding-bottom: 30px;
}
.simplecalenderHeadline {
  font-size: 36px;
  margin-top: 6px !important;
}
.SimpleCalendar {
  thead {
    tr {
      th {
        border: 1px solid #fff;
        width: 60px;
        height: 60px;
        background: $simplecalender-header-bg;
        text-align: center;
        color: #fff;
      }
    }
  }
  tbody {
    tr {
      td {
        border: 1px solid #fff;
        width: 60px;
        height: 60px;
        background: #e1e1e1;
        text-align: center;
        color: #666;
        position: relative;
      }
      .SCEMPTYDATE {
        background: transparent !important;
      }
      .today {
        font-weight: bold;
      }
      .hasDate {
        background: $simplecalender-hasdate-bg;
        color: #fff;
      }
      .eventover {
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        position: absolute;
      }
    }
  }
}

.kalender-listing {
  .eintrag {
    padding-bottom: 10px;
    padding-top: 10px;
    transition: all 0.5s;
    border-bottom: 1px solid #e5e5e5;
    .datum {
      font-size: 24px;
      font-weight: bold;
      color: $simplecalender-hasdate-bg
    }
    .titel {
      font-size: 24px;
    }
    .text {
      margin-left: 15px;
    }
  }
}

.eintrag-highlight {
  background: rgba(0,0,0,0.1);
}

#scPrev, #scNext {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0px;
  i {
    img,svg {
      width: 40px;
      height: 40px;
      margin-bottom: 20px;
    }
  }
}

#scPrev {
  left: 0px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
}

#scNext {
  right: 0px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
}