@import "background";
@import "shadows";

.align-lt {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.align-lb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.align-rt {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.align-rb {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.align-cc {
  display: flex;
  justify-content: center;
  align-items: center;
}
.align-cr {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.align-cl {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.width-full {
  display: flex;
  width: 100%;
}
.width-flull > div {
  flex: 1;
}

.bgColor-white {
  .bgColor-none {
    .tinyMCE {
      color: #000000 !important;
      a {
        color: #000000 !important;
      }
    }
  }
}