.module-video {
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  position: relative;
  .video-shadow {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%;
    img {
      width: 100%;
      bottom: -1px;
      transform: rotate(180deg);
    }
  }
}