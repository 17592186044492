$container-color-1: #ff00ff !default;
$container-color-1--h1-color: #ffffff !default;
$container-color-1--h2-color: #ffffff !default;
$container-color-1--h2-mix-blend-mode: normal !default;

$container-color-2: #ffff00 !default;
$container-color-2--h1-color: #ffffff !default;
$container-color-2--h2-color: #ffffff !default;
$container-color-2--h2-mix-blend-mode: normal !default;

$container-color-3: #ff0000 !default;
$container-color-3--h1-color: #ffffff !default;
$container-color-3--h2-color: #ffffff !default;
$container-color-3--h2-mix-blend-mode: normal !default;

$container-color-4: #00ff00 !default;
$container-color-4--h1-color: #ffffff !default;
$container-color-4--h2-color: #ffffff !default;
$container-color-4--h2-mix-blend-mode: normal !default;

$container-color-5: #00ffff !default;
$container-color-5--h1-color: #ffffff !default;
$container-color-5--h2-color: #ffffff !default;
$container-color-5--h2-mix-blend-mode: normal !default;

$container-color-6: #666666 !default;
$container-color-7: transparent !default;

.tinyMCE {
  width: 100%;
}

.tinyMCEbackground-1,
.tinyMCEbackground-2,
.tinyMCEbackground-3 {
  height: 100%;
  padding: 30px;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  hr {
    width: 100%;
  }
  h2 {
    color: #fff !important;
  }
}
.grid-container {
  background: #ffffff;
  z-index: 10;
}

.tinyMCEbackground-1 {
  background: rgba(0,99,115, 1.0);
}
.tinyMCEbackground-2 {
  background: rgba(67,177,179, 1.0);
}
.tinyMCEbackground-3 {
  background: rgba(234,91,143, 1);
}

.container-color-1:before,
.container-color-2:before,
.container-color-3:before,
.container-color-4:before {
  font-weight: bold;
  font-size: 300px;
  line-height: 286px;
  margin-left: -10px;
  position: absolute;
}
.container-color-1, .container-color-2, .container-color-3 {
  position: relative;
  display: flex;
  >div {
    position: relative;
  }
}

.container-color-4 {
  background: transparent;
}

.container-color-1 {
  background: $container-color-1;
  h1 {
    color: $container-color-1--h1-color !important;
  }
  h2 {
    color: $container-color-1--h2-color !important;
    mix-blend-mode: $container-color-1--h2-mix-blend-mode;
  }
}

.container-color-2 {
  background: $container-color-2;
  h1 {
    color: $container-color-2--h1-color !important;
  }
  h2 {
    color: $container-color-2--h2-color !important;
    mix-blend-mode: $container-color-2--h2-mix-blend-mode;
  }
}
.container-color-3 {
  background: $container-color-3;
  h1 {
    color: $container-color-3--h1-color !important;
  }
  h2 {
    color: $container-color-3--h2-color !important;
    mix-blend-mode: $container-color-3--h2-mix-blend-mode;
  }
}
.container-color-4 {
  background: $container-color-4;
  h1 {
    color: $container-color-4--h1-color !important;
  }
  h2 {
    color: $container-color-4--h2-color !important;
    mix-blend-mode: $container-color-4--h2-mix-blend-mode;
  }
}

.container-color-5 {
  background: $container-color-5;
  h1 {
    color: $container-color-5--h1-color !important;
  }
  h2 {
    color: $container-color-5--h2-color !important;
    mix-blend-mode: $container-color-5--h2-mix-blend-mode;
  }
}

.container-color-6 {
  background: transparent !important;
}

.container-color-7 {
  background: $container-color-7;
}

.backgroundelement {
  position: absolute !important;
  bottom: 0px;
  pointer-events: none;
  z-index: 1;
  mix-blend-mode: multiply;

  img,svg {
    fill: #ccc;
    .st0 {
      fill: #ccc;
    }
  }
}

.backgroundelement_right {
  right: 0px;
}

.backgroundelement_left {
  left: 0px;
}