$primary-color: #eb2323;
$primary-color-light: rgb(153, 0, 0);
$primary-color-dark: rgb(153, 0, 0);
$secondary-color: #157178 !default;
$secondary-color-dark: #004750 !default;
$header--height: 125px;
$header--bg-color: #9bb600;

$ci-color-1: #f07d00;
$ci-color-2: #565656;
$ci-color-3: #a9a9a9;
$ci-color-4: #f3f3f3;
$ci-color-5: #ebff41;
$ci-color-6: #a69772;
$ci-color-7: #766a4c;

$farbe-1: #e0e0e0;
$farbe-1-hover: #133157;
$farbe-2: $ci-color-1;
$farbe-3: $ci-color-2;
$farbe-4: $ci-color-3;

$navigation--background: #299b66;
$navbar--bg-1: #ffffff;
$navbar-nav-item--font-family: 'Eurostile';

$footer--bg-color: $ci-color-7;
$font-family-base: 'Eurostile';
$h1--font-family: 'Eurostile';
$h1--font-weight: 400;
$h1--font-color: $ci-color-1;

$h2--font-family: 'Eurostile';
$h2--font-weight: 400;
$h2--font-size: 25px;
$h2--font-color: $ci-color-2;

$font-weight-base: 200;
$headings-font-weight: 200;
$font-size-base: 1.3rem !important;

$link-color: $farbe-1;

$farbe-tct: #666666;
$farbe-tct-hover: #eb2323;

$a--color: #000000;
$a-hover--color: $ci-color-1;

$container-color-1: $ci-color-1;
$container-color-1--h1-color: $ci-color-3;
$container-color-1--h2-color: $ci-color-2;
$container-color-1--h2-mix-blend-mode: none !important;

$container-color-2: #cecece;
$container-color-2--h1-color: $ci-color-1;
$container-color-2--h2-color: $ci-color-3;
$container-color-2--h2-mix-blend-mode: none !important;

$container-color-3: url('../../../media/bg_struktur-2.png') repeat-x #fff;
$container-color-3--h1-color: $container-color-1--h1-color;
$container-color-3--h2-color: $container-color-1--h2-color;
$container-color-3--h2-mix-blend-mode: $container-color-1--h2-mix-blend-mode;

$container-color-4: $ci-color-4;
$container-color-4--h1-color: $ci-color-3;
$container-color-4--h2-color: $ci-color-1;
$container-color-4--h2-mix-blend-mode: $container-color-1--h2-mix-blend-mode;

$container-color-5: url('../../../media/bg_struktur-1.png') repeat-x #fff;
$container-color-5--h1-color: $ci-color-1;
$container-color-5--h2-color: $ci-color-2;

$sitemap--sub-font-size: 16px !important;

$allgemeineinfo--a-hover: $container-color-5--h1-color;

$navbar--bg: $ci-color-1;
$navbar--width: 100%;
$navbar--color: #fff !important;
$navbar--font-size: 20px !important;
$navbar--height: 60px !important;

$shortcut-icon-fill: $ci-color-1;
$shortcut-font-size: 1.2rem;
$shortcut-font-weight: 400;
$kontakt-icons--a-color: $ci-color-1;
$kontakt-icons--a-color-hover: $ci-color-4;

$kontakt-icons--a-color-dark: #fff;
$kontakt-icons--a-color-hover-dark: rgba(255,255,255,0.5);

$downloads--color: $kontakt-icons--a-color !important;
$downloads--hover-color: $kontakt-icons--a-color-hover !important;
$simplecalender-header-bg: $ci-color-2;
$simplecalender-hasdate-bg: $ci-color-1;

@import "navs/topnav";
@import "modules/header";
@import "modules/kontakt_icons";
//@import "modules/logocloud";
@import "modules/grid";
@import "modules/teaser";
@import "modules/teaser-halb";
//@import "navs/bottomnav";
@import "modules/video";
@import "modules/infobox";
@import "modules/news";
@import "modules/sitemap";
@import "modules/iconsmitlinks";
@import "modules/bilderslider";
//@import "modules/haeuser";
@import "modules/team";
//@import "modules/jobs";
@import "modules/boxen";
//@import "modules/lageplan";
@import "modules/parallax-gallery";
@import "modules/bildmittext-box";
@import "modules/allgemeineinfos";
@import "modules/seminarboxen";
@import "modules/shortcut";
@import "modules/hintergrundelemente";
@import "modules/bild_und_box";
@import "modules/cat-listing";
@import "modules/kalender";
@import "modules/klassenlisting";
@import "modules/downloads";
@import "modules/scrollblock";

@import "addons/contentFade";

$container-max-widths: (
  xs: 540px,
  sm: 720px,
  md: 960px,
  lg: 1140px,
  xl: 1500px
);

html, body {
  overscroll-behavior-y: none;
  text-rendering: geometricPrecision;
  .container-fluid {
    overflow: hidden;
  }
}

body {
  ::selection {
    text-shadow: none;
    background: $ci-color-1;
    color: #eee;
  }
  header {
    position: fixed !important;
    top: 0px;
    z-index: 999;
    background: none !important;
    height: 125px !important;
    #header-dynamic-background {
      opacity: 0.9;
      background: #fff;
    }
    .schule-fuer-alle {
      height: 40px;
      width: 160px;
      position: absolute;
      top: 105px;
      left: 90px;
      transform: translateX(-50%);
      z-index: 999999;
      img,svg {
        height: 100% !important;
        width: 100% !important;
        top: unset !important;
        left: unset!important;
        position: unset !important;
        margin-left: 110px;
      }
    }
    #page-settings {
      position: absolute;
      right: 20px;
      top: 40px;
      display: flex;
      flex-direction: row;
      font-size: 12px;
      .devider {
        width: 1px;
        height: 65px;
        margin-left: 10px;
        margin-right: 10px;
        border-left: 1px solid #736d5f;
      }
      .btn-settings {
        justify-content: center;
        display: flex;
        flex-direction: column;
        text-align: center;
        .btn-wrapper {
          display: flex; flex-direction: row; justify-content: center; justify-items: center;
        }
        i {
          display: flex;
          justify-items: center;
          justify-content: center;
          margin-right: 10px;
          img,svg {
            color: #736d5f;
            fill: #736d5f !important;

            .st0 {
              fill: #736d5f !important;
            }
          }
        }
        img, svg {
          width: 40px;
        }
      }
    }
    #header-silhouette {
      position: relative;
      background: #9bb600;
      border-bottom: 20px solid #bed63f;
      height: 125px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      #logo {
        position: relative;
        height: 100%;
      }
      img,svg {
        position: absolute;
        left: 10px;
        top: 0px;
        z-index: 999999;
        width: 368px;
      }
    }
    #logo {
      a {
        picture {
          img {
            margin-top: 15px;
            height: 100px;
            transform: translateX(-50%) !important;
          }
        }
      }
    }
    #navigation {
      margin-top: 0px;
      .navbar-toggler {
        top: 42px;
        left: 50%;
        transform: translateX(-368px);
        width: 60px;
        border-left: 1px solid $ci-color-2;
        border-radius: 0px !important;
        .navbar-toggler-icon {
          width: 60px;
          height: 60px;
        }
      }
      nav {
        #navbarSupportedContent {
          left: 50%;
          margin-left: -556px;
          max-width: 343px;
          transform: translateX(-50%);
          margin-top: -13px;
          margin-right: 0px;
          position: relative;
          background: transparent !important;
          .navbar-nav {
            justify-content: space-between;
            align-content: center;
            display: flex;
            flex-direction: column;
            width: 100%;
            .nav-item {
              display: flex;
              background: transparent;
              width: 100%;
              text-transform: uppercase;
              justify-content: center;
              flex-direction: column;
              align-self: flex-start;
              .nav-link {
                color: #fff;
                width: 100%;
                text-align: center;
                z-index: 999;
                font-family: "Eurostile";
                letter-spacing: 1px;
                font-size: 2rem;
              }
              .dropdown-menu {
                width: 100%;
                z-index: 999;
                padding-top: 0px;
                margin-top: 0px;
                .dropdown-item {
                  color: #fff;
                  font-weight: normal;
                  text-transform: none;
                }
                .dropdown-menu-list {
                  flex-direction: column;
                  align-content: center;
                }
              }
            }
            .nav-item {
              margin-top: 2px !important;
              .nav-link {
                color: $ci-color-2;
                background: $ci-color-4;
                height: 60px;
                transition: all 0.3s;
                text-shadow: none !important;
                font-weight: 400 !important;
              }
              .nav-link:hover {
                color: $ci-color-1 !important;
                opacity: 0.9;
              }
            }
          }
          .shd-box-main .shd-box:before {
            left: 12px !important;
            box-shadow: 0 12px 15px rgba(100,100,100,.8) !important;
            width: 50%;
          }
          .shd-box-main .shd-box:after {
            content: " ";
            box-shadow: 0 12px 15px rgba(100,100,100,.8);
            height: 150px;
            transform: rotate(2deg);
            width: 50%;
            top: -100%;
            right: 12px;
            position: absolute;
            transform-origin: bottom right;
          }
          .dropdown-menu {
            display: none;
            top: 0px !important;
            padding-top: 0px !important;
            left: 0px;
            z-index: 1;
            .dropdown-menu-list {
              display: flex;
              flex-flow: row wrap;
              justify-content: center;
              flex-wrap: wrap;
              padding-top: 30px;
              padding-bottom: 20px;
              .dropdown-item {
                max-width: 250px;
                min-width: calc(25% - 40px);
                margin-left: 20px;
                margin-right: 20px;
                text-align: center;
                margin-bottom: 10px;
                font-size: 1.3rem;
              }
            }
          }
          .show {
            display: flex;
          }
        }
      }
    }
    #header-icon {
      height: 65px;
      width: 150px;
      right: 10px;
      top: 30px;
      position: absolute;
      flex-direction: row;
      padding-top: 17px;
      padding-right: 20px;
      a {
        margin-top: auto;
        margin-right: 10px;
        margin-bottom: auto;
        i {
          svg,img {
            height: 30px;
            color: $ci-color-2;
          }
        }
      }
    }
  }
  h1 {
    -webkit-font-smoothing: antialiased;
    margin-bottom: 15px;
    text-transform: uppercase;
  }
  h2 {
    margin-bottom: 20px;
  }
  main {
    padding-top: 125px;
  }
  .bg-primary, .bg-secondary, .bg-hellblau, .bg-dunkelblau {
    .kontakticons {
      a {
        transition: opacity 0.3s;
        color: $ci-color-2 !important;
        i {
          img, svg {
            fill: $ci-color-2 !important;
            color: $ci-color-2 !important;
            transition: color 0.3s;
            opacity: 1;
          }
        }
      }

      a:hover {
        i {
          img, svg {
            opacity: 0.9;
          }
        }
      }
    }
    .downloads {
      a {
        color: $kontakt-icons--a-color-dark !important;
        img,svg {
          color: $kontakt-icons--a-color-dark !important;
          fill: $kontakt-icons--a-color-dark !important;
        }
      }
      a:hover {
        color: $kontakt-icons--a-color-hover-dark !important;
        img,svg {
          color: $kontakt-icons--a-color-hover-dark !important;
          fill: $kontakt-icons--a-color-hover-dark !important;
        }
      }
    }
  }
  .bg-primary {
    background-color: $farbe-2 !important;
    color: $ci-color-4 !important;
    h1 {
      text-shadow: -1px -1px rgba(0,0,0,0.3);
      color: $ci-color-5;
    }
    h2{
      color: $ci-color-5;
    }
    hr {
      border-color: $ci-color-5 !important;
      opacity: 0.2;
    }
    .cat-listing {
      svg,img {
        fill: $ci-color-2;
        color: $ci-color-2;
      }
      hr {
        border-color: #fff !important;
      }
      a {
        color: $ci-color-2;
      }
      a:hover {
        color: $ci-color-2;
      }
    }
  }
  .bg-secondary {
    background-color: $ci-color-2 !important;
    color: #fff;
    h1 {
      text-shadow: -1px -1px rgba(0,0,0,0.3);
      color: #fff;
    }
    h2 {
      color: #fff;
    }
    hr {
      border-color: #fff !important;
      opacity: 0.2;
    }
    .cat-listing {
      hr {
        border-color: $ci-color-3 !important;
        opacity: 0.3;
      }
      svg,img {
        fill: $ci-color-3;
        color: $ci-color-3;
      }
      a {
        color: $ci-color-3;
      }
      a:hover {
        color: $ci-color-3;
      }
    }
  }
  .bg-hellblau {
    background-color: $ci-color-3 !important;
    color: #fff;
    h1 {
      text-shadow: -1px -1px rgba(0,0,0,0.3);
      color: #fff;
    }
    h2 {
      color: #fff;
    }
    hr {
      border-color: rgba(255,255,255,0.25) !important;
    }
    .cat-listing {
      hr {
        border-color: $ci-color-3 !important;
        opacity: 0.3;
      }
      svg,img {
        fill: $ci-color-3;
        color: $ci-color-3;
      }
      a {
        color: $ci-color-3;
      }
      a:hover {
        color: $ci-color-3;
      }
    }
  }
  .bg-dunkelblau {
    background-color: $ci-color-4 !important;
    color: $ci-color-2;
    h1 {
      text-shadow: 1px 1px rgba(255,255,255,1);
      color: $ci-color-1 !important;
    }
    h2 {
      //text-shadow: 1px 1px rgba(255,255,255,0.3);
      color: $ci-color-2 !important;
    }
    hr {
      border-color: #fff !important;
    }
    .cat-listing {
      hr {
        border-color: $ci-color-3 !important;
        opacity: 0.3;
      }
      svg,img {
        fill: $ci-color-3;
        color: $ci-color-3;
      }
      a {
        color: $ci-color-3;
      }
      a:hover {
        color: $ci-color-3;
      }
    }
  }
  .bg-success {
    background-color: $farbe-3;
    color: #000;
    .cat-listing {
      hr {
        border-color: $ci-color-3 !important;
        opacity: 0.3;
      }
      svg,img {
        fill: $ci-color-4;
        color: $ci-color-4;
      }
      a {
        color: $ci-color-4;
      }
      a:hover {
        color: $ci-color-4;
      }
    }
  }
  footer {
    color: #fff !important;
    .footer {
      border-top-color: $ci-color-5 !important;
      background: $ci-color-3 !important;
      color: #fff !important;
      border-bottom: 40px solid $ci-color-5 !important;
      a {
        color: #fff !important;
      }
    }
    a {
      color: #fff !important;
      transition: all 0.3s;
    }
    .footer-logo-text {
      background: #0886c3;
      font-size: 25px;
      text-transform: uppercase;
      .footer-logo-text-schule {
        font-family: "Eurostile";
        font-size: 30px;
        font-weight: 600;
      }
    }
    a:hover {
      color: rgba(255,255,255,.8) !important;
    }
    .w-25 {
      width: 30% !important;
    }
    h2 {
      color: #fff;
      font-size: 30px !important;
      margin-bottom: 10px !important;
      font-weight: 600 !important;
      font-synthesis-weight: none !important;
    }
    .tinyMCE {
      table {
        max-width: 180px;
      }
    }
    .kontakticons-full-detail {
        text-align: center;
        top: -31px;
        position: relative;
        div {
          position: relative;
        }
    }
  }
  .pagination {
    .page-item {

    }
    .page-link {
      color: $ci-color-2;
    }
    .active {
      .page-link {
        background-color: $ci-color-2;
        border-color: $ci-color-2;
      }
    }
  }
}

  .site-map--lev1 {
    li:nth-child(1) {
      background: $ci-color-1;
      h5 {
        a {
          color: #736d5f;
        }
      }
      ul {
        li {
          a {
            color: #736d5f !important;
          }
        }
      }
    }
    li:nth-child(2) {
      background: $ci-color-2;
    }
    li:nth-child(3) {
      background: $ci-color-3;
    }
    li:nth-child(4) {
      background: $ci-color-4;
    }
    .site-map--lev2 {
      li {
        background: transparent;
      }
    }
  }

  .kontakt-bild {
    width: 150px;
    height: 100%;
    float:left;
    margin-right: 10px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.5);
    picture {
      position: relative;
      width: 100%;
      height: 100%;
      img {
        position: relative;
        width: 100%;
        height: 100%;
      }
    }
  }

  .teaser--fixed-text{
    position: fixed;
    bottom: 20px;
    font-size: 60px;
    left: 50%;
    transform: translateX(-50%);
    font-family: "Eurostile";
    font-weight: 400;
    text-align: center;
    border-bottom: 1px solid #ffffff;
    color: #ffffff;
  }

  b, strong {
    font-weight: 700 !important;
  }

  .klassen-listing {
    li {
      margin-left: 20px;
    }
  }

  .news-startseite h3 {
    color: $ci-color-1;
    transition: color 0.3s;
  }

  a:hover .news-startseite h3 {
    color: $ci-color-2;
  }

  .comp1-data {
    text-align:center;
  }