.parallax-bilder {
  height: 100%;
  min-height: 400px;
  picture {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      min-width: 100%;
      will-change: transform;
      position: absolute;
    }
  }
}
.parallax-bilder-fixed {
  picture {
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}