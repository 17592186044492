@include media-breakpoint-down(lg) {
  body {
    #teaservideo {
      width: 100%;
      min-height: unset !important;
      margin-top: 65px;
    }
    main {
      .row {
        margin-left: 10px;
        margin-right: 10px;
        width: calc(100% - 20px);
        .col-md-12 {
          padding-right: 0px !important;
          padding-left: 0px !important;
        }
        .infobox {
          margin-left: 0px !important;
          margin-right: 0px !important;
        }
      }
      .cat-listing {
        margin-left: 0px;
        margin-right: 0px;
        width: 100%;
      }
      .container-fluid {
        //padding: 0px !important;
        .teaser {
          height: calc(100vw / 16 * 9);
        }
      }
    }
  }
}