$contentfade-transition: opacity 1s ease-in-out 0s, transform 0.6s ease-in-out 0s !default;
$contentfade-opacity-init: 1 !default;
$contentfade-opacity-finish: 1 !default;
$contentfade-transform-init: translateY(30px) !default;
$contentfade-transform-finish: none !default;

main {
  .fadeing {
    //opacity: $contentfade-opacity-init;
    .row {
      transition: $contentfade-transition;
      transform: $contentfade-transform-init;
    }
  }

  .fadeContentIn {
    opacity: $contentfade-opacity-finish !important;

    .row {
      transform: $contentfade-transform-finish !important;
    }
  }
}