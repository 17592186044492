.teaser--halb {
    width: 100%;
    height: calc(100vh / 1.2);
    max-height: 400px;
    min-height: 300px;
    position: relative;
    overflow: hidden;
    .teaser--halb-teaser {
        max-height: 400px;
        min-height: 150px;
    }
    picture {
        position: relative;
        width: 100%;
        img {
            position: relative;
            width: 100%;
        }
    }
}