$mod-header--font-size: 50px !default;
$mod-header--font-family: $font-family-base !default;
$mod-header--text-transform: uppercase !default;
$mod-header-headline--top: 0px !default;
$mod-header-headline--left: 50% !default;
.header {
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  will-change: transform;
  #particles-js {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-image: url('');
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 9999;
    canvas{
      display:block;
      vertical-align:bottom;
    }
  }
  #particles {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    canvas {
      width: 100%;
      height: 100%;
    }
  }
  picture {
    width: 100%;
    height: auto;
    position: relative;
    img {
      width: calc(100% - 200px);
      margin: 100px;
    }
  }
  .header--text {
    position: absolute;
    top: $mod-header-headline--top;
    left: $mod-header-headline--left;
    font-size: $mod-header--font-size;
    text-transform: $mod-header--text-transform;
    transform: translateX(-50%);
  }
}