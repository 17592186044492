.bild-box {
  width: 100%;
  position: relative;
  height: calc(100vw / 2 / 16 * 12);
  .bild-box-single {
    position: absolute;
    height: 100%;
    opacity: 0;
    left: 100%;
    top: 0px;
    transition: all 1.5s;
    width: 100%;
    .row {
      height: 100%;
      .bild {
        h1 {
          position: absolute;
          right: 20px;
          color: #ffffff;
          max-width: 50% !important;
          margin-top: 30px !important;
          text-align: right;
          z-index: 999;
          text-shadow: none !important;
        }
        .bilddetail {
          opacity: 0;
          transition: all 0.6s;
          picture {
            width: 100%;
            display: flex;
            position: absolute;
            top: 0px;
            left: 0px;
            height: 100%;
            img {
              width: 100%;
              height: calc(100vw / 2 / 16 * 12);
            }
          }
        }

        .bullits {
          position: absolute;
          bottom: 0px;
          width: 100%;
          height: 50px;
          display: flex;
          justify-content: center;
          .bullit {
            background: #133157;
            position: relative;
            width: 20px;
            height: 20px;
            border-radius: 20px;
            margin-right: 5px;
            margin-left: 5px;
            box-shadow: 0px 0px 5px rgba(100,100,100,0.3);
            cursor: pointer;
          }
          .show {
            background: #fff;
          }
        }
      }

      .box {
        background: #ffffff;
        position: absolute;
        left: 50%;
        transition: left 1s;
        height: 100%;
        h2 {
          margin-top: 55px !important;
          text-align: left !important;
        }
      }
    }
  }
  .showPicDetail {
    opacity: 1 !important;
  }
  .show {
    opacity: 1 !important;
    left: 0% !important;
  }
  .hideLeft {
    left: -100% !important;
    .box {
      left: 10% !important;
    }
  }

  .arrow_right {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100px;
    height: 100px;
    font-size: 80px;
    font-weight: bold;
    cursor: pointer;
    img, svg {
      position: absolute;
      bottom: 10px;
      right: 10px;
      transition: opacity 0.3s;
    }
    img:hover {
      opacity: 0.7;
    }
  }
}
@include media-breakpoint-down(xl) {
  .bild-box {
    width: calc(100% + 20px);
    margin-left: -20px;
    .bild-box-single {
      .row {
        .box {
          h2 {
            margin-top: 48px!important;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .bild-box {
    width: calc(100% + 20px);
    margin-left: -20px;
    .bild-box-single {
      .row {
        .box {
          h2 {
            margin-top: 48px!important;
          }
        }
      }
    }
    .arrow_right {
      img,svg {
        height: 100px;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .bild-box {
    height: calc(100vw * 2);
    .bild-box-single {
      .row {
        width: 100%;
        margin-left: 20px;

        .bild {
          width: 100%;
          padding: 0px !important;
          height: 50%;
          .bilddetail {
            picture {
              img {
                height: 100vw;
              }
            }
          }
        }
        .box {
          top: 50%;
          height: 50%;
          left: 20px !important;
          padding-left: 20px !important;
          padding-right: 20px !important;
        }
      }
    }
    .arrow_right {
      bottom: 50%;
      text-shadow: 0px 0px 2px #fff;
      img,svg {
        bottom: -50%;
        height: 100px;
      }
    }
  }
}