.grid-shadow-left, .grid-shadow-right, .grid-shadow-complet {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: hidden;
  z-index: 9999;
  height: 150px;
  pointer-events: none !important;
  mix-blend-mode: multiply;
  .shd-box {
    width: 100%;
    height: 150px;
  }
  .shd-box:before {
    content: " ";
    box-shadow: 0 25px 35px rgba(100, 100, 100, 0.8);
    width: 100%;
    position: absolute;
    height: 150px;
    mix-blend-mode: multiply;
  }
}

.grid-shadow-left {
  .shd-box:before {
    transform: rotate(-2deg);
    top: -100%;
    left: -10%;
    transform-origin: bottom left;
  }
}

.grid-shadow-right {
  .shd-box:before {
    transform: rotate(2deg);
    top: -100%;
    right: -10%;
    transform-origin: bottom right;
  }
}

.grid-shadow-complet {
  .shd-box:before {
    transform: rotate(0deg);
    top: -100%;
    right: 0px;
    transform-origin: bottom right;
  }
}