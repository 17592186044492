footer {
  z-index: 60;
  background: transparent;
  padding: 0px;
  margin: 0px;
  position: relative;
  width: 100%;
  min-height: 50px;
  .container-fluid {
    overflow: visible !important;
  }

  .schule-fuer-alle {
    height: 40px;
    position: absolute;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    img,svg {
      height: 100%;
      width: 100%;
      margin-left: 110px;
    }
  }
  .footer-kopf {
    width: 100%;
    position: relative;
    height: 100px;
    z-index: 99999999;
    img {
      width: 250px;
      left: 50%;
      position: relative;
      margin-top: -8px;
      z-index: 9999;
      transform: translateX(-50%);
    }
  }


  .kontakticons {
    max-width: 230px;
    margin-left: auto;
    margin-right: auto;
    .text {
      display: none;
    }
    .fontawsome-icon {
      svg {
        color: #fff;
      }
    }
  }
  .site-map {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    li {
      width: calc(100% / 4);
      text-align: center;
      padding-top: 40px;
      padding-bottom: 40px;
      h5 {
        a {
          color: #fff;
          font-weight: 400;
          font-size: 25px;
        }
      }
      .site-map--lev2 {
        width: 100%;
        li {
          padding-top: 10px;
          padding-bottom: 0px;
          width: 100%;
          a {
            font-size: 12px;
            font-weight: normal;
            text-transform: none;
            color: #ffffff;
          }
        }
      }
    }
  }
  .footer {
    text-align: center;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    color: #6ebce2;
    padding-top: 30px;
    padding-bottom: 30px;
    background: #0886c3;
    border-bottom: 20px solid #ff00ff;
    overflow: hidden;
    a {
      color: #6ebce2;
      font-weight: normal;
    }
    a:hover {
      color: #565656 !important;
    }
    h2 {
      font-synthesis-weight: none !important;
    }
  }
}