@include media-breakpoint-down(sm) {
  html {
    overflow-x: hidden;
    width: 100vw;
  }
  body {
    h1 {
      line-height: normal;
    }
    main {
      padding-top: 0px !important;
      margin-top: 0px !important;
      .scrollblock {
        .scrollblock-chapter-content {
          width: 100vw !important;
          left: 0px !important;
          top: 65% !important;
          padding-left: 15px;
          padding-right: 15px;
        }
        .scrollblock-avatar {
          display: none !important;
        }
        .scrollblock-chapter-logo {
          position: absolute !important;
          top: -20px !important;
          width: 220vw !important;
          picture {
            img {
              width: 220vw !important;
            }
          }
        }
        .scrollblock-chapter-content-no-logo {
          top: 40% !important;
        }
      }
    }
    .teaser--fixed-text {

    }
    #header-dynamic-background {
      min-height: 120px !important;
      max-height: 120px !important;
      height: 120px !important;
      display: none !important;
    }
    header {
      #header-icon {
        top: 100px;
        height: 35px;
        left: 50%;
        transform: translateX(-50%);
        padding-top: 5px;
        padding-right: 10px;
        width: 165px;
        a {
          margin-right: 5px;
          i {
            img,svg {
              height: 50px;
            }
          }
        }
        a:last-child {
          margin-right: 0px !important;
        }
      }
      .shd-box:before {
        top: -106% !important;
        transform: rotate(-3deg) !important;
      }
    }
    #header-silhouette, header {
      height: 95px !important;
    }
    #header-silhouette {
      img,svg {
        height: 95px !important;
      }
      .schule-fuer-alle {
        top: 75px !important;
        left: 50px !important;
        width: 130px !important;
        height: 30px !important;
        img,svg {
          height: 100% !important;
        }
      }
    }
    #teaserBilder {
      .teaser--bg {
        top: 100px !important;
        picture {
          img {
            max-height: 280px !important;
            min-height: unset !important;
            min-height: unset !important;
          }
        }
      }
    }
    #navigation {
      width: 100vw !important;
      height: 100px !important;
      nav {
        #navbarSupportedContent {
          overflow: scroll;
          .navbar-nav {
            .nav-item {
              .nav-link {
                height: 40px !important;
                font-size: 1.2rem !important;
              }
              .dropdown-menu {
                .dropdown-menu-list {
                  .dropdown-item {
                    font-size: 1rem !important;
                  }
                }
              }
            }
          }
          .shd-box-main {
            display: none !important;
          }
        }
      }
      #scrollleiste {
        bottom: calc(100% - 2px);
        height: 2px;
      }
      .navbar-toggler {
        top: 50%;
        transform: translateY(-50%);
        display: block;
        right: 0px !important;
        top: 38px !important;
      }

      .shd-box-main .shd-box:before {
        box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important;
        transform: rotate(-2deg) !important;
      }
      #logo {
        top: 0px;
        a {
          left: 50%;
          transform: translateX(-50%) !important;
          height: 110%;
          img {

          }
        }
        .header-logo {
          height: 80% !important;
          top: 5px !important;
        }
        .logo-subheadline {
          width: 100%;
          left: 15px;
          transform: none !important;
          top: 50px;
          text-align: left;
          font-size: 10px;
          color: #333333 !important;
        }
        #nav-devider {
          top: 25px;
          height: calc(100% - 35px);
          width: calc(100vw - 30px);
          .nav-top-icons {
            a {
              i {
                img, svg {
                  height: 30px;
                }
              }
            }
          }
        }
        .show {
          .navbar-nav {
            opacity: 1 !important;
          }
        }
        .navbar-nav {
          height: auto !important;
          opacity: 0 !important;

          flex-direction: column !important;
          .nav-item {
            flex-direction: column;
            .dropdown-menu {
              .dropdown-item {
                margin-left: auto;
                margin-right: auto;
                transform: unset !important;
              }
            }
          }
          .nav-link {
            left: unset !important;
            transform: none !important;
            margin-left: auto;
            margin-right: auto;
          }
        }
      }
    }
    main {
      #teaVideo {
        min-height: 120px !important;
      }
      #teaVideo:before {
        box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important;
      }
      .shd-box-main .shd-box:before {
        box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important;
        transform: rotate(5deg) !important;
      }
      .container-fluid {
        >.row {
          margin: 0px !important;
          width: calc(100%);
        }
      }
      .kontakticons {
        display: flex;
        //flex-direction: column;
        a {
          margin-bottom: 40px !important;
          i {
            justify-content: center;
            display: flex;
          }
        }
      }
      .tinyMCE {
        padding: 0px 10px !important;
      }

      .parallax-gallery {
        height: calc(100vw / 4 * 5) !important;
        width: calc(100% + 40px) !important;
        min-height: 150px !important;
        .parallax-gallery-inner {
          .gallery-pic {
            background-attachment: scroll;
            width: 102vw !important;
          }
          .gallery-pic:before {
            position: fixed;
            content: ' ';
            display: block;
            z-index: -10;
          }
        }
      }
    }
    .mobile-footer {

    }
    footer {
      padding-bottom: 0px !important;
      .schule-fuer-alle {
        width: 130px !important;
        height: 30px !important;
        margin-left: -50px;
        img,svg {
          height: 100% !important;
        }
      }
      .shd-box-main .shd-box:before {
        box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important;
        transform: rotate(-2deg) !important;
      }
      h2 {
        font-size: 30px !important;
        font-weight: 400 !important;
        font-synthesis-weight: none !important;
      }
      .comp_info {
        float: unset !important;
        margin-right: 0px !important;
        margin-left: 0px !important;
        margin-bottom: 50px !important;
        .comp1-data {
          width: 100%;
          display: flex;
          flex-direction: column;
          .comp1-name {
            h2 {
              text-align: center;
            }
          }
        }
      }
      .footer {
        .shd-box-main {
          .shd-box {
            height: 80px;
          }
          .shd-box:before {
            box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important;
            transform: rotate(-2deg) !important;
          }
        }
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .parallax-bilder {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  #teaserBilder {
    .teaser--bg {
      top: 100px !important;
    }
  }

  body {
    #navigation {
      .shd-box-main {
        z-index: 1;
        .shd-box:before {
          left: 0px !important;
        }
      }
    }
    main {
      h1 {
        font-size: 45px !important;
      }
      h2 {
        font-size: 20px !important;
      }
    }
    @include media-breakpoint-down(sm) {
      main {

      }
      #header {
        height: 50px !important;
        max-width: 100vw !important;

        .header__top {
          height: 50px !important;
        }

        #headerlogo {
          width: 100%;

          img {
            height: 33.65px !important;
            width: 375px !important;
          }
        }
      }
      .bildergalerie--article {
        width: 100vw !important;
        height: 100vh !important;
        overflow: hidden;

        .teaser--bg {
          max-width: 100vw !important;
          max-height: 100vh !important;
        }
      }

      .teaser--main {
        .teaser {
          min-height: 280px !important;
          max-height: 450px !important;
        }
      }
    }
    footer {
      min-height: 520px !important;
      padding-bottom: 0px !important;
      .oeffnungszeitenFooter {
        max-width: 100%;
        margin-left: 0px;
        .row {
          max-width: 100% !important;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }
      }
    }
  }
}