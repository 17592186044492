$sitemap--a-color: #000 !default;
$sitemap--font-size: 21px !default;
$sitemap--sub-a-color: #000 !default;
$sitemap--sub-font-size: 16px !default;
.site-map {
  display: flex;
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 0px;
  li {
    list-style-type: none;
    ul {
      padding-left: 0px;
      a {
        color: $sitemap--sub-a-color;
        font-size: $sitemap--sub-font-size;
      }
    }
    a {
      line-height: 21px;
      font-size: $sitemap--font-size;
      color: $sitemap--a-color;
      white-space: normal;
      font-weight: 900;
      text-transform: uppercase;
    }
  }
}
.sitemap--nebeneinander {
  flex-direction: row;
  justify-content: space-around;
}

.sitemap--untereinander {
  flex-direction: column;
  li {
    ul {
      padding-bottom: 30px;
    }
  }
}