.buttons-modul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  a {
    justify-content: center;
    text-align: center;
    transition: color 0.3s;
    margin: 0px 30px;
    font-weight: 600;
    color: $farbe-1;
  }
  a:first-child {
    margin-left: 0px !important;
  }
  a:last-child {
    margin-right: 0px !important;
  }
  .btn-modul:before {
    content: ' ';
    float: left;
  }
  .btn-modul {
    display: flex;
    justify-content: center;
    display: flex;
    position: relative;
    i {
      font-size: 60px;
      padding: 25px;
      border-radius: 10px;
      background: $farbe-1;
      color: #ffffff;
      margin-bottom: 10px;
      transition: color 0.3s;
      width: 180px;
      height: 180px;
      display: flex;
      transition: color,background 0.3s;
      img,svg {
        width: 100%;
        transform: translateY(0px);
      }
    }
  }

  a:hover {
    i {
      color: $farbe-1-hover;
      background: $farbe-1-hover;
    }
    color: $farbe-1-hover;
  }
}