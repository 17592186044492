.news-startseite {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  margin: 40px 0 20px;
  height: auto;
  width: 100%;
  background: #fff;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 0 0 10px rgba(0,0,0,.1);
  border-radius: 5px;
  h3 {
    font-size: 25px !important;
    text-shadow: none !important;
    height: 40px;
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
  }
  .news--bild {
    width: 100%;
    position: relative;
    picture {
      width: 100%;
      height: auto;
      img {
        width: 100%;
      }
    }
  }
  .news--text {
    height: 90px;
    color: #333333;
    overflow: hidden;
    font-weight: normal;
    position: relative;
    font-size: 1.1rem;
    .news-text-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(255,255,255,0) 32%, rgba(255,255,255,1) 92%);
    }
  }
  hr {
    height: 1px !important;
    background: rgba(255,255,255,0.2) !important;
    border-top: 1px solid rgba(0,0,0,0.2) !important;
    width: 343px;
    transform: rotate(-1deg);
    margin-top: 0px;
    position: absolute;
    bottom: 73px;
  }
  .news--icon {
    position: absolute;
    width: 50px;
    right: 20px;
    bottom: 66px;
    svg {
      fill: #006d45;
    }
  }
}

#news-details {
  .row {
    flex-direction: row;
  }
}

.news-gallery {
  margin-top: 45px;
}

.news-gallery-pic {
  margin-bottom: 10px;
  picture {
    img {
      width: 100%;
    }
  }
}

#newsbilder {
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px;
  .newsbilder-bild {
    width: 100%;
    height: 100%;
    picture {
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}

.newsbox {
  background: #fff;
  padding: 20px 20px 10px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
  width: calc(100% / 3 - 20px);
  .news__media {
    .news__media-img-container {
      display: flex;
      flex-direction: column;
      box-shadow: 0 0 10px rgba(100,100,100,0.5);
      picture {
        display: flex;
        flex-direction: column;
        img {
          width: calc(200% / 16 * 9);
          height: calc(100% / 16 * 9);
        }
      }
    }
  }
  a {
    text-decoration: none;
    color: #000;
    font-weight: 400;
    flex-direction: column;
    height: 100%;
    display: flex;
    transition: opacity 0.3s;
  }
  a:hover {
    color: unset !important;
    opacity: 0.8;
  }
  picture {
    max-width: 100%;
    img {
      max-width: 100%;
    }
  }
  .news__headline {
    h4 {
      color: #EB2323;
      margin: 10px 0px 10px 10px;
    }
  }
  .news__content {
    padding: 0 0 0;
    font-size: 18px;
    flex-grow: 2;
  }
  .news--verlinkung {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    svg {
      max-width: 60px;
      max-height: 60px;
      height: 60px;
      width: 60px;
      z-index: 1;
    }
    .news--datum {
      position: absolute;
      left: 5px;
      top: 2px;
    }
  }
  .news--verlinkung-line {
    width: 100%;
    border-top: 1px solid #ccc;
    height: 1px;
    position: absolute;
  }
}

.news-archiv {
  .news-startseite {
    height: 350px !important;
    max-height: unset !important;
    h3 {
      height: 60px !important;
    }
  }
}

@include media-breakpoint-down(sm) {
    .news-startseite {
      max-width: 100% !important;
    }
  .news-startseite-anker {
    position: relative;
    max-width: 100% !important;
  }
  #news-details {
    .row {
      flex-direction: column-reverse;
    }
    hr {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .news-archiv {
    .news-startseite {
      height: auto !important;
    }
  }
}

@include media-breakpoint-down(lg) {
  @include media-breakpoint-up(sm) {
    .news-startseite {
      width: 100% !important;
    }
  }
}