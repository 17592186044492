.team {
  margin-top: 10px;
  .mitarbeiter {
    margin: 0px;
    text-align: center;
    margin-bottom: 30px;
    background: #fff;
    .mitarbeiter-box {
      padding: 20px;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
    }
    .mitarbeiter-bild {
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 30px;
      img {
        width: 100%;
        box-shadow: none;
      }
    }
    .mitarbeiter-name {
      font-size: 23px !important;
      font-family: "PT Sans";
      text-shadow: none!important;
      color: #0886c3!important;
      height: 25px;
      text-align: center;
    }
    .mitarbeiter-bezeichnung {
      margin-bottom: 20px;
    }
    .mitarbeiter-kontakt {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center;
      .mitarbeiter-telefon, .mitarbeiter-email {
        margin-right: 10px;
        margin-left: 10px;
        i {
          img,svg {
            width: 50px;
          }
        }
        a {
          transition: opacity 0.3s;
        }
        a:hover {
          color: unset !important;
          opacity: 0.8;
        }
      }
    }

    .mitarbeiter-position {
      font-size: 0.7rem;
    }
    .mitarbeiter-position2 {
      margin-bottom: 50px;
      margin-top: 10px;
    }
    .mitarbeiter-klasse {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      .mitarbeiter-klasse-box, .mitarbeiter-email-box {
        background: #bed63f;
        font-size: 21px;
        color: #fff;
        font-weight: bold;
        padding: 2px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 40px;
        line-height: 35px;
        height: 40px;
      }
      .mitarbeiter-email-box {
        width: 41px;

        i {
          img,svg {
            color: #bed63f;
            transition: color 0.3s;
          }
        }
        a:hover {
          i {
            img,svg {
              color: #9bb600;
            }
          }
        }
      }
      .mitarbeiter-email-box-devider {
        border-left: 1px solid rgba(0,0,0,.1);
        margin-left: 5px;
        padding-left: 5px;
      }
    }
  }
  .mitarbeiter-darstellung-ausfuehrlich {
    .mitarbeiter-box {
      display: flex;
      flex-direction: row;
      .mitarbeiter-infos {
        width: 30%;
        position: relative;
      }
      .mitarbeiter-description {
        width: 70%;
        ul {
          li {
            text-align: left;
          }
        }
      }
    }
  }
}