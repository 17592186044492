.klassen-listing {
  .klasse {
    padding: 20px;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.10);
    text-align: center;
    .klassen-box {
      background: #bed63f;
      font-size: 21px;
      color: #fff;
      font-weight: bold;
      padding: 2px;
      position: relative;
      margin-left: auto;
      margin-right: auto;
      width: 40px;
      line-height: 35px;
      height: 40px;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}