/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Eurostile';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/eurostil.ttf'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/eurostil.woff') format('woff'), /* Modern Browsers */
  url('../fonts/eurostil.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/eurostil.woff2') format('woff2'); /* Legacy iOS */
  font-display: swap;
}


/* pt-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/pt-sans-v17-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* pt-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/pt-sans-v17-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}


/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-condensed-v25-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-condensed-v25-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-condensed-v25-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-condensed-v25-latin-300.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-condensed-v25-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-condensed-v25-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-condensed-v25-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-condensed-v25-latin-regular.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-condensed-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../fonts/roboto-condensed-v25-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-condensed-v25-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-condensed-v25-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-condensed-v25-latin-700.svg#RobotoCondensed') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* roboto-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../fonts/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/roboto-v30-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v30-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v30-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v30-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v30-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
  font-display: swap;
}
/* roboto-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: url('../fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
  font-display: swap;
}

/* yanone-kaffeesatz-200 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/yanone-kaffeesatz-v29-latin-200.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}
/* yanone-kaffeesatz-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/yanone-kaffeesatz-v29-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}