.infobox {
  margin-left: -30px !important;
  margin-right: -30px !important;

  .box1 {
    background: $farbe-1;
    h2 {
      text-shadow: -1px -1px 0px #b01a1a;
    }
  }
  .box2 {
    background: $farbe-2;
    h2 {
      text-shadow: -1px -1px 0px #9c7622;
    }
  }
  .box3 {
    background: $farbe-3;
    h2 {
      text-shadow: -1px -1px 0px #2f2106;
    }
  }
  h2 {
    color: #fff;
    margin-top: 30px;
    font-size: 20px;
    margin-bottom: 10px;
  }
  .content {
    color: #ffffff;
    margin-bottom: 30px;
  }
  .box-firstsection {
    height: 130px;
  }
  .box-lastsection {
    color: #ffffff;
    a {
      color: #ffffff;
      img {
        transition: opacity 0.3s;
      }
    }
    a:hover {
      color: rgba(255,255,255,0.75) !important;
      img {
        opacity: 0.75;
      }
    }
  }
  .box-pic {
    width: 100%;
    img {
      width: 100%;
      border: 10px solid #ffffff;
    }
  }
}