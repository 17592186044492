.bildmittext-box {
  .row {
    .bildbox {
      position: relative;
      picture {
        max-width: 100%;
        img {
          max-width: 100%;
        }
      }
    }
  }
  picture {
    max-width: 100%;
    img {
      max-width: 100%;
      box-shadow: 0px 0px 10px rgba(100,100,100,0.5);
      margin-bottom: 20px;
    }
  }
}

@include media-breakpoint-down(md) {
  .bildmittext-box {
    width: calc(100% - 20px);
    margin-left: 10px !important;
  }
}