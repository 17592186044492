/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Eurostile';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/eurostil.ttf");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/eurostil.woff") format("woff"), url("../fonts/eurostil.ttf") format("truetype"), url("../fonts/eurostil.woff2") format("woff2");
  /* Legacy iOS */
  font-display: swap; }

/* pt-sans-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/pt-sans-v17-latin-regular.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* pt-sans-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/pt-sans-v17-latin-700.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* roboto-condensed-300 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-condensed-v25-latin-300.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v25-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v25-latin-300.woff2") format("woff2"), url("../fonts/roboto-condensed-v25-latin-300.woff") format("woff"), url("../fonts/roboto-condensed-v25-latin-300.ttf") format("truetype"), url("../fonts/roboto-condensed-v25-latin-300.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* roboto-condensed-regular - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-condensed-v25-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v25-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v25-latin-regular.woff2") format("woff2"), url("../fonts/roboto-condensed-v25-latin-regular.woff") format("woff"), url("../fonts/roboto-condensed-v25-latin-regular.ttf") format("truetype"), url("../fonts/roboto-condensed-v25-latin-regular.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* roboto-condensed-700 - latin */
@font-face {
  font-family: 'Roboto Condensed';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-condensed-v25-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""), url("../fonts/roboto-condensed-v25-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-condensed-v25-latin-700.woff2") format("woff2"), url("../fonts/roboto-condensed-v25-latin-700.woff") format("woff"), url("../fonts/roboto-condensed-v25-latin-700.ttf") format("truetype"), url("../fonts/roboto-condensed-v25-latin-700.svg#RobotoCondensed") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* roboto-300 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url("../fonts/roboto-v30-latin-300.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/roboto-v30-latin-300.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v30-latin-300.woff2") format("woff2"), url("../fonts/roboto-v30-latin-300.woff") format("woff"), url("../fonts/roboto-v30-latin-300.ttf") format("truetype"), url("../fonts/roboto-v30-latin-300.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* roboto-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto-v30-latin-regular.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/roboto-v30-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v30-latin-regular.woff2") format("woff2"), url("../fonts/roboto-v30-latin-regular.woff") format("woff"), url("../fonts/roboto-v30-latin-regular.ttf") format("truetype"), url("../fonts/roboto-v30-latin-regular.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* roboto-700 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto-v30-latin-700.eot");
  /* IE9 Compat Modes */
  src: url("../fonts/roboto-v30-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/roboto-v30-latin-700.woff2") format("woff2"), url("../fonts/roboto-v30-latin-700.woff") format("woff"), url("../fonts/roboto-v30-latin-700.ttf") format("truetype"), url("../fonts/roboto-v30-latin-700.svg#Roboto") format("svg");
  /* Legacy iOS */
  font-display: swap; }

/* yanone-kaffeesatz-200 - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 200;
  src: url("../fonts/yanone-kaffeesatz-v29-latin-200.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

/* yanone-kaffeesatz-regular - latin */
@font-face {
  font-display: swap;
  /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Yanone Kaffeesatz';
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/yanone-kaffeesatz-v29-latin-regular.woff2") format("woff2");
  /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */ }

.parallax-bilder {
  height: 100%;
  min-height: 400px; }
  .parallax-bilder picture {
    position: relative;
    display: flex;
    width: 100%;
    height: 100%; }
    .parallax-bilder picture img {
      width: 100%;
      min-width: 100%;
      will-change: transform;
      position: absolute; }

.parallax-bilder-fixed picture {
  height: 100%; }
  .parallax-bilder-fixed picture img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

header .header--shadow {
  position: absolute;
  top: 126px; }

.navbar-toggler-icon {
  background: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23565656' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important; }

.navbar {
  padding: 0px !important;
  width: 100%;
  height: 60px !important;
  background-color: transparent;
  top: 4px;
  box-shadow: none !important; }
  .navbar .socialmediaicons {
    position: absolute;
    top: -165px;
    left: 0px;
    padding: 10px;
    z-index: 9999;
    color: #a87f66; }
    .navbar .socialmediaicons i {
      color: #a87f66; }
      .navbar .socialmediaicons i svg {
        height: 25px; }
      .navbar .socialmediaicons i img {
        height: 25px; }
  .navbar .text-light i {
    color: #ffffff !important; }
  .navbar .socialmediaicons-scrolled {
    color: #ffffff;
    position: absolute;
    top: -50px; }
  .navbar .btn {
    margin-left: 10px;
    background: #007bff;
    color: #ccc;
    border: 0px; }
  .navbar .logo {
    height: 30px;
    position: absolute;
    top: 0px;
    left: 0px; }
  .navbar .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
    font-size: 20px !important;
    font-family: Arial;
    text-transform: initial;
    width: 100%;
    padding: 0px 0px;
    justify-content: space-between;
    height: 60px !important; }
    .navbar .navbar-nav .nav-item {
      display: flex;
      justify-content: center;
      position: relative;
      flex: auto; }
      .navbar .navbar-nav .nav-item a {
        color: #fff !important;
        display: flex;
        align-items: center;
        font-family: 'Roboto Condensed';
        animation: unset !important;
        bottom: unset !important;
        font-weight: 300;
        margin-top: 5px !important; }
      .navbar .navbar-nav .nav-item > a:hover {
        opacity: 0.5;
        color: #fff !important; }
      .navbar .navbar-nav .nav-item .dropdown-toggle::after {
        content: unset; }
    .navbar .navbar-nav .dropdown-toggle::after {
      content: unset; }
    .navbar .navbar-nav .active {
      color: #fff !important; }
      .navbar .navbar-nav .active a {
        color: #fff !important; }
    .navbar .navbar-nav .submenue {
      display: flex;
      flex-direction: column;
      font-size: 20px; }
      .navbar .navbar-nav .submenue .dropdown-item {
        background: none;
        font-size: 30px; }
  .navbar .dropdown-menu {
    left: unset !important;
    top: 80px;
    display: none;
    flex-direction: column;
    border: 0px;
    box-shadow: 0px 0px 5px #999;
    width: 100%;
    border-radius: 0px;
    background: rgba(255, 255, 255, 0.9); }
    .navbar .dropdown-menu .dropdown-item {
      font-size: 30px;
      text-align: center;
      text-transform: none;
      font-weight: 600;
      justify-content: center;
      text-shadow: 1px 1px #ffffff !important; }
    .navbar .dropdown-menu .submenue-second {
      font-size: 20px;
      padding: 0px 80px; }
  .navbar .show {
    display: flex;
    margin-top: -8px;
    background-color: #f07d00; }
    .navbar .show > .nav-link {
      color: #fff !important; }
  .navbar .shd-box-main {
    height: 80px !important; }
    .navbar .shd-box-main .shd-box:before {
      left: -10% !important;
      right: unset !important;
      transform: rotate(-2deg) !important;
      transform-origin: bottom left !important;
      bottom: 100%;
      box-shadow: 0 20px 15px rgba(0, 0, 0, 0.2) !important;
      top: unset !important; }
    .navbar .shd-box-main .shd-box:after {
      content: " ";
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2) !important;
      transform: rotate(0deg);
      top: -100%;
      right: -10%;
      width: 100%;
      position: absolute;
      height: 65px;
      mix-blend-mode: multiply;
      transform-origin: bottom left; }

.navbar-toggler {
  position: fixed;
  top: 50px;
  right: 10px;
  z-index: 999999; }

.navbar-dark .navbar-toggler {
  color: #ffffff;
  border-color: #ffffff; }

#navbarSupportedContent {
  transition: 0.3s !important; }

.header {
  width: 100%;
  min-height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  will-change: transform; }
  .header #particles-js {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-image: url("");
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    z-index: 9999; }
    .header #particles-js canvas {
      display: block;
      vertical-align: bottom; }
  .header #particles {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999; }
    .header #particles canvas {
      width: 100%;
      height: 100%; }
  .header picture {
    width: 100%;
    height: auto;
    position: relative; }
    .header picture img {
      width: calc(100% - 200px);
      margin: 100px; }
  .header .header--text {
    position: absolute;
    top: 0px;
    left: 50%;
    font-size: 50px;
    text-transform: uppercase;
    transform: translateX(-50%); }

.kontakticons {
  position: relative;
  display: flex;
  flex-direction: row;
  font-size: 70px;
  justify-content: space-around;
  margin-bottom: 30px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  max-width: 750px;
  z-index: 10; }
  .kontakticons a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #f07d00;
    transition: opacity 0.3s; }
  .kontakticons a:hover {
    color: #f3f3f3;
    opacity: 0.7; }
  .kontakticons i {
    padding: 0px 30px 10px 30px;
    width: 130px;
    transform: translateX(-50%);
    position: relative;
    left: 50%; }
    .kontakticons i svg, .kontakticons i img {
      height: 70px;
      color: #f07d00;
      fill: #f07d00; }
  .kontakticons .text {
    font-size: 16px;
    text-align: center; }

.dunkle-icons a {
  color: #fff !important; }

.dunkle-icons a:hover {
  color: rgba(255, 255, 255, 0.5) !important; }

.tinyMCE {
  width: 100%; }

.tinyMCEbackground-1,
.tinyMCEbackground-2,
.tinyMCEbackground-3 {
  height: 100%;
  padding: 30px;
  color: #fff !important;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .tinyMCEbackground-1 hr,
  .tinyMCEbackground-2 hr,
  .tinyMCEbackground-3 hr {
    width: 100%; }
  .tinyMCEbackground-1 h2,
  .tinyMCEbackground-2 h2,
  .tinyMCEbackground-3 h2 {
    color: #fff !important; }

.grid-container {
  background: #ffffff;
  z-index: 10; }

.tinyMCEbackground-1 {
  background: #006373; }

.tinyMCEbackground-2 {
  background: #43b1b3; }

.tinyMCEbackground-3 {
  background: #ea5b8f; }

.container-color-1:before,
.container-color-2:before,
.container-color-3:before,
.container-color-4:before {
  font-weight: bold;
  font-size: 300px;
  line-height: 286px;
  margin-left: -10px;
  position: absolute; }

.container-color-1, .container-color-2, .container-color-3 {
  position: relative;
  display: flex; }
  .container-color-1 > div, .container-color-2 > div, .container-color-3 > div {
    position: relative; }

.container-color-4 {
  background: transparent; }

.container-color-1 {
  background: #f07d00; }
  .container-color-1 h1 {
    color: #a9a9a9 !important; }
  .container-color-1 h2 {
    color: #565656 !important;
    mix-blend-mode: none !important; }

.container-color-2 {
  background: #cecece; }
  .container-color-2 h1 {
    color: #f07d00 !important; }
  .container-color-2 h2 {
    color: #a9a9a9 !important;
    mix-blend-mode: none !important; }

.container-color-3 {
  background: url("../../../media/bg_struktur-2.png") repeat-x #fff; }
  .container-color-3 h1 {
    color: #a9a9a9 !important; }
  .container-color-3 h2 {
    color: #565656 !important;
    mix-blend-mode: none !important; }

.container-color-4 {
  background: #f3f3f3; }
  .container-color-4 h1 {
    color: #a9a9a9 !important; }
  .container-color-4 h2 {
    color: #f07d00 !important;
    mix-blend-mode: none !important; }

.container-color-5 {
  background: url("../../../media/bg_struktur-1.png") repeat-x #fff; }
  .container-color-5 h1 {
    color: #f07d00 !important; }
  .container-color-5 h2 {
    color: #565656 !important;
    mix-blend-mode: normal; }

.container-color-6 {
  background: transparent !important; }

.container-color-7 {
  background: transparent; }

.backgroundelement {
  position: absolute !important;
  bottom: 0px;
  pointer-events: none;
  z-index: 1;
  mix-blend-mode: multiply; }
  .backgroundelement img, .backgroundelement svg {
    fill: #ccc; }
    .backgroundelement img .st0, .backgroundelement svg .st0 {
      fill: #ccc; }

.backgroundelement_right {
  right: 0px; }

.backgroundelement_left {
  left: 0px; }

.teaser {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 0px; }
  .teaser .teaser--bg {
    width: 100%;
    height: 110%;
    position: absolute;
    opacity: 0;
    transition: opacity 0.6s; }
    .teaser .teaser--bg img {
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .teaser .active {
    opacity: 1; }
  .teaser .teaser--shadow {
    position: absolute;
    bottom: -10px;
    width: 100vw;
    height: 0px;
    box-shadow: 0px -10px 30px 10px #000000;
    transform: rotate(-0.5deg);
    z-index: 999; }
  .teaser article {
    position: absolute;
    top: 50%;
    left: 0%;
    width: 100vw;
    padding-left: 180px;
    padding-right: 180px; }
    .teaser article h1 {
      font-size: 90px;
      color: #ccc;
      font-weight: 600;
      text-align: left;
      border-bottom: 0px !important;
      mix-blend-mode: color-dodge;
      opacity: 0.5; }
    .teaser article p {
      font-size: 34px;
      color: #ccc;
      opacity: 1;
      text-align: left;
      mix-blend-mode: color-dodge;
      opacity: 0.5; }
    .teaser article .embed-responsive {
      box-shadow: 0px 0px 15px #000;
      background: #007bff;
      border-radius: 15px; }
    .teaser article .fade-in {
      opacity: 0; }
  .teaser .fade-rtl, .teaser .fade-ltr {
    transform: matrix(1, 0, 0, 1, 0, 0);
    transition: all, 0s, 0s;
    transition-duration: 3s, 3s;
    transition-property: transform, opacity;
    transition-timing-function: ease-in-out, linear;
    will-change: opacity, transform;
    opacity: 0; }
  .teaser .fade-rtl {
    transform: translate(50px); }
  .teaser .fade-ltr {
    transform: translate(-50px); }
  .teaser #conversionbuttonindex2 {
    position: absolute;
    right: 20%;
    bottom: 35%;
    opacity: 0;
    font-size: 35px;
    box-shadow: 0px 0px 15px #333;
    padding: 10px 20px 10px 20px;
    transition: all 0.5s ease; }

.has-open, .has-closed {
  position: absolute;
  z-index: 999;
  height: auto;
  transform: translate(-50%, -80px);
  left: 50%;
  background: #ffffff;
  width: 580px;
  display: flex;
  height: 80px;
  font-family: 'Yanone Kaffeesatz'; }
  .has-open .clock, .has-closed .clock {
    width: 85px;
    height: 60px;
    margin-top: 10px;
    border-right: 1px solid #cdc0b0;
    margin-right: 15px; }
    .has-open .clock i, .has-closed .clock i {
      font-size: 60px;
      color: #cdc0b0; }
      .has-open .clock i svg, .has-open .clock i img, .has-closed .clock i svg, .has-closed .clock i img {
        height: 60px;
        transform: translate(10px, -15px); }
  .has-open .text, .has-closed .text {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .text-closed, .has-closed .text-closed {
    font-size: 50px;
    font-weight: bold;
    color: #301610;
    margin-top: 5px; }
  .has-open .zeit, .has-closed .zeit {
    position: relative;
    width: 165px;
    margin-left: 20px;
    margin-top: 10px;
    font-weight: bold;
    font-size: 35px;
    color: #cdc0b0; }

.teaser--shadow {
  width: 100%;
  height: 10px;
  background: transparent;
  transform: translateY(0px);
  z-index: 1001;
  position: absolute;
  box-shadow: 0px -7px 10px rgba(0, 0, 0, 0.5) !important; }

.teaser--main {
  overflow: hidden;
  position: relative;
  margin-left: -30px;
  margin-right: -30px; }

.has-closed {
  width: 450px; }

.teaser--halb {
  width: 100%;
  height: calc(100vh / 1.2);
  max-height: 400px;
  min-height: 300px;
  position: relative;
  overflow: hidden; }
  .teaser--halb .teaser--halb-teaser {
    max-height: 400px;
    min-height: 150px; }
  .teaser--halb picture {
    position: relative;
    width: 100%; }
    .teaser--halb picture img {
      position: relative;
      width: 100%; }

.module-video {
  margin-left: -30px;
  margin-right: -30px;
  display: flex;
  position: relative; }
  .module-video .video-shadow {
    position: absolute;
    bottom: -1px;
    left: 0px;
    width: 100%; }
    .module-video .video-shadow img {
      width: 100%;
      bottom: -1px;
      transform: rotate(180deg); }

.infobox {
  margin-left: -30px !important;
  margin-right: -30px !important; }
  .infobox .box1 {
    background: #e0e0e0; }
    .infobox .box1 h2 {
      text-shadow: -1px -1px 0px #b01a1a; }
  .infobox .box2 {
    background: #f07d00; }
    .infobox .box2 h2 {
      text-shadow: -1px -1px 0px #9c7622; }
  .infobox .box3 {
    background: #565656; }
    .infobox .box3 h2 {
      text-shadow: -1px -1px 0px #2f2106; }
  .infobox h2 {
    color: #fff;
    margin-top: 30px;
    font-size: 20px;
    margin-bottom: 10px; }
  .infobox .content {
    color: #ffffff;
    margin-bottom: 30px; }
  .infobox .box-firstsection {
    height: 130px; }
  .infobox .box-lastsection {
    color: #ffffff; }
    .infobox .box-lastsection a {
      color: #ffffff; }
      .infobox .box-lastsection a img {
        transition: opacity 0.3s; }
    .infobox .box-lastsection a:hover {
      color: rgba(255, 255, 255, 0.75) !important; }
      .infobox .box-lastsection a:hover img {
        opacity: 0.75; }
  .infobox .box-pic {
    width: 100%; }
    .infobox .box-pic img {
      width: 100%;
      border: 10px solid #ffffff; }

.news-startseite {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  margin: 40px 0 20px;
  height: auto;
  width: 100%;
  background: #fff;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px; }
  .news-startseite h3 {
    font-size: 25px !important;
    text-shadow: none !important;
    height: 40px;
    text-align: center;
    margin-top: 20px;
    font-weight: bold; }
  .news-startseite .news--bild {
    width: 100%;
    position: relative; }
    .news-startseite .news--bild picture {
      width: 100%;
      height: auto; }
      .news-startseite .news--bild picture img {
        width: 100%; }
  .news-startseite .news--text {
    height: 90px;
    color: #333333;
    overflow: hidden;
    font-weight: normal;
    position: relative;
    font-size: 1.1rem; }
    .news-startseite .news--text .news-text-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 32%, white 92%); }
  .news-startseite hr {
    height: 1px !important;
    background: rgba(255, 255, 255, 0.2) !important;
    border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
    width: 343px;
    transform: rotate(-1deg);
    margin-top: 0px;
    position: absolute;
    bottom: 73px; }
  .news-startseite .news--icon {
    position: absolute;
    width: 50px;
    right: 20px;
    bottom: 66px; }
    .news-startseite .news--icon svg {
      fill: #006d45; }

#news-details .row {
  flex-direction: row; }

.news-gallery {
  margin-top: 45px; }

.news-gallery-pic {
  margin-bottom: 10px; }
  .news-gallery-pic picture img {
    width: 100%; }

#newsbilder {
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px; }
  #newsbilder .newsbilder-bild {
    width: 100%;
    height: 100%; }
    #newsbilder .newsbilder-bild picture img {
      width: 100%;
      height: auto; }

.newsbox {
  background: #fff;
  padding: 20px 20px 10px;
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 50px;
  width: calc(100% / 3 - 20px); }
  .newsbox .news__media .news__media-img-container {
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px rgba(100, 100, 100, 0.5); }
    .newsbox .news__media .news__media-img-container picture {
      display: flex;
      flex-direction: column; }
      .newsbox .news__media .news__media-img-container picture img {
        width: calc(200% / 16 * 9);
        height: calc(100% / 16 * 9); }
  .newsbox a {
    text-decoration: none;
    color: #000;
    font-weight: 400;
    flex-direction: column;
    height: 100%;
    display: flex;
    transition: opacity 0.3s; }
  .newsbox a:hover {
    color: unset !important;
    opacity: 0.8; }
  .newsbox picture {
    max-width: 100%; }
    .newsbox picture img {
      max-width: 100%; }
  .newsbox .news__headline h4 {
    color: #EB2323;
    margin: 10px 0px 10px 10px; }
  .newsbox .news__content {
    padding: 0 0 0;
    font-size: 18px;
    flex-grow: 2; }
  .newsbox .news--verlinkung {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%; }
    .newsbox .news--verlinkung svg {
      max-width: 60px;
      max-height: 60px;
      height: 60px;
      width: 60px;
      z-index: 1; }
    .newsbox .news--verlinkung .news--datum {
      position: absolute;
      left: 5px;
      top: 2px; }
  .newsbox .news--verlinkung-line {
    width: 100%;
    border-top: 1px solid #ccc;
    height: 1px;
    position: absolute; }

.news-archiv .news-startseite {
  height: 350px !important;
  max-height: unset !important; }
  .news-archiv .news-startseite h3 {
    height: 60px !important; }

@media (max-width: 767.98px) {
  .news-startseite {
    max-width: 100% !important; }
  .news-startseite-anker {
    position: relative;
    max-width: 100% !important; }
  #news-details .row {
    flex-direction: column-reverse; }
  #news-details hr {
    margin-top: 40px;
    margin-bottom: 40px; }
  .news-archiv .news-startseite {
    height: auto !important; } }

@media (max-width: 1199.98px) and (min-width: 576px) {
  .news-startseite {
    width: 100% !important; } }

.site-map {
  display: flex;
  list-style-type: none;
  margin-left: 0px;
  padding-left: 0px;
  text-align: center;
  padding-bottom: 0px;
  margin-bottom: 0px; }
  .site-map li {
    list-style-type: none; }
    .site-map li ul {
      padding-left: 0px; }
      .site-map li ul a {
        color: #000;
        font-size: 16px !important; }
    .site-map li a {
      line-height: 21px;
      font-size: 21px;
      color: #000;
      white-space: normal;
      font-weight: 900;
      text-transform: uppercase; }

.sitemap--nebeneinander {
  flex-direction: row;
  justify-content: space-around; }

.sitemap--untereinander {
  flex-direction: column; }
  .sitemap--untereinander li ul {
    padding-bottom: 30px; }

.buttons-modul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px; }
  .buttons-modul a {
    justify-content: center;
    text-align: center;
    transition: color 0.3s;
    margin: 0px 30px;
    font-weight: 600;
    color: #e0e0e0; }
  .buttons-modul a:first-child {
    margin-left: 0px !important; }
  .buttons-modul a:last-child {
    margin-right: 0px !important; }
  .buttons-modul .btn-modul:before {
    content: ' ';
    float: left; }
  .buttons-modul .btn-modul {
    display: flex;
    justify-content: center;
    display: flex;
    position: relative; }
    .buttons-modul .btn-modul i {
      font-size: 60px;
      padding: 25px;
      border-radius: 10px;
      background: #e0e0e0;
      color: #ffffff;
      margin-bottom: 10px;
      transition: color 0.3s;
      width: 180px;
      height: 180px;
      display: flex;
      transition: color,background 0.3s; }
      .buttons-modul .btn-modul i img, .buttons-modul .btn-modul i svg {
        width: 100%;
        transform: translateY(0px); }
  .buttons-modul a:hover {
    color: #133157; }
    .buttons-modul a:hover i {
      color: #133157;
      background: #133157; }

.bilderslider {
  justify-content: center;
  display: flex;
  border: 10px solid #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  margin-bottom: 30px; }
  .bilderslider .bilder--slider {
    width: 100%;
    height: 394px;
    overflow: hidden;
    position: relative; }
    .bilderslider .bilder--slider div {
      width: 100%;
      height: auto; }
    .bilderslider .bilder--slider picture {
      opacity: 0;
      transition: opacity 0.8s;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%; }
      .bilderslider .bilder--slider picture img {
        width: 100%;
        position: absolute;
        top: 0px;
        left: 0px; }
    .bilderslider .bilder--slider .active {
      opacity: 1; }
      .bilderslider .bilder--slider .active picture {
        opacity: 1; }
  .bilderslider .bilder-slider-preview {
    position: absolute;
    bottom: 70px;
    height: 83px;
    width: auto;
    display: flex;
    flex-direction: row;
    justify-content: center; }
    .bilderslider .bilder-slider-preview picture {
      position: relative;
      height: 72px;
      width: 120px;
      padding: 5px;
      margin: 0px 10px;
      cursor: pointer; }
      .bilderslider .bilder-slider-preview picture img {
        width: calc(100% - 10px);
        position: absolute;
        top: 5px;
        left: 5px;
        opacity: 1;
        transition: opacity 0.8s;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
        border: 3px solid #ffffff; }
    .bilderslider .bilder-slider-preview .active img {
      opacity: 0.5; }

.team {
  margin-top: 10px; }
  .team .mitarbeiter {
    margin: 0px;
    text-align: center;
    margin-bottom: 30px;
    background: #fff; }
    .team .mitarbeiter .mitarbeiter-box {
      padding: 20px;
      width: 100%;
      height: 100%;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); }
    .team .mitarbeiter .mitarbeiter-bild {
      width: 100%;
      aspect-ratio: 1/1;
      margin-bottom: 30px; }
      .team .mitarbeiter .mitarbeiter-bild img {
        width: 100%;
        box-shadow: none; }
    .team .mitarbeiter .mitarbeiter-name {
      font-size: 23px !important;
      font-family: "PT Sans";
      text-shadow: none !important;
      color: #0886c3 !important;
      height: 25px;
      text-align: center; }
    .team .mitarbeiter .mitarbeiter-bezeichnung {
      margin-bottom: 20px; }
    .team .mitarbeiter .mitarbeiter-kontakt {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: center; }
      .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-telefon, .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-email {
        margin-right: 10px;
        margin-left: 10px; }
        .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-telefon i img, .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-telefon i svg, .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-email i img, .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-email i svg {
          width: 50px; }
        .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-telefon a, .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-email a {
          transition: opacity 0.3s; }
        .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-telefon a:hover, .team .mitarbeiter .mitarbeiter-kontakt .mitarbeiter-email a:hover {
          color: unset !important;
          opacity: 0.8; }
    .team .mitarbeiter .mitarbeiter-position {
      font-size: 0.7rem; }
    .team .mitarbeiter .mitarbeiter-position2 {
      margin-bottom: 50px;
      margin-top: 10px; }
    .team .mitarbeiter .mitarbeiter-klasse {
      display: flex;
      justify-content: center;
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%); }
      .team .mitarbeiter .mitarbeiter-klasse .mitarbeiter-klasse-box, .team .mitarbeiter .mitarbeiter-klasse .mitarbeiter-email-box {
        background: #bed63f;
        font-size: 21px;
        color: #fff;
        font-weight: bold;
        padding: 2px;
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 40px;
        line-height: 35px;
        height: 40px; }
      .team .mitarbeiter .mitarbeiter-klasse .mitarbeiter-email-box {
        width: 41px; }
        .team .mitarbeiter .mitarbeiter-klasse .mitarbeiter-email-box i img, .team .mitarbeiter .mitarbeiter-klasse .mitarbeiter-email-box i svg {
          color: #bed63f;
          transition: color 0.3s; }
        .team .mitarbeiter .mitarbeiter-klasse .mitarbeiter-email-box a:hover i img, .team .mitarbeiter .mitarbeiter-klasse .mitarbeiter-email-box a:hover i svg {
          color: #9bb600; }
      .team .mitarbeiter .mitarbeiter-klasse .mitarbeiter-email-box-devider {
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        margin-left: 5px;
        padding-left: 5px; }
  .team .mitarbeiter-darstellung-ausfuehrlich .mitarbeiter-box {
    display: flex;
    flex-direction: row; }
    .team .mitarbeiter-darstellung-ausfuehrlich .mitarbeiter-box .mitarbeiter-infos {
      width: 30%;
      position: relative; }
    .team .mitarbeiter-darstellung-ausfuehrlich .mitarbeiter-box .mitarbeiter-description {
      width: 70%; }
      .team .mitarbeiter-darstellung-ausfuehrlich .mitarbeiter-box .mitarbeiter-description ul li {
        text-align: left; }

.modul-boxen .row {
  justify-content: center; }

.modul-boxen .modul-box {
  margin-bottom: 30px;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); }
  .modul-boxen .modul-box h3 {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    border-top: 1px solid #999;
    background-size: cover !important;
    width: 100%;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 400;
    font-size: 20px;
    color: #7e1213;
    text-shadow: none;
    margin-bottom: 0px;
    min-height: 60px; }
  .modul-boxen .modul-box .headline-1 {
    background: url("../../../media/wood_1.png") #f07d00; }
  .modul-boxen .modul-box .headline-2 {
    background: url("../../../media/wood_2.png") #565656; }
  .modul-boxen .modul-box .headline-3 {
    background: url("../../../media/wood_3.png") #a9a9a9; }
  .modul-boxen .modul-box .headline-4 {
    background: url("../../../media/wood_4.png") #f3f3f3; }
  .modul-boxen .modul-box .headline-5 {
    background: url("../../../media/wood_5.png") #ebff41; }
  .modul-boxen .modul-box .job-bild {
    width: 100%;
    height: auto;
    position: relative; }
    .modul-boxen .modul-box .job-bild picture {
      width: 100%;
      height: 0px;
      position: relative; }
      .modul-boxen .modul-box .job-bild picture img {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1; }
  .modul-boxen .modul-box .beschreibung {
    padding: 10px 10px 0px 10px;
    min-height: 150px; }
  .modul-boxen .modul-box .box-infos {
    width: 100%;
    height: auto;
    padding: 5px;
    background: #f07d00;
    position: relative;
    color: #fff; }
    .modul-boxen .modul-box .box-infos i {
      position: absolute;
      right: 12px; }
      .modul-boxen .modul-box .box-infos i img, .modul-boxen .modul-box .box-infos i svg {
        padding: 5px;
        width: 30px; }
  .modul-boxen .modul-box .box-infos-1 {
    background: #f07d00; }
  .modul-boxen .modul-box .box-infos-2 {
    background: #565656; }
  .modul-boxen .modul-box .box-infos-3 {
    background: #a9a9a9; }
  .modul-boxen .modul-box .box-infos-4 {
    background: #f3f3f3; }
  .modul-boxen .modul-box .box-infos-5 {
    background: #ebff41; }

.parallax-gallery {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 15px);
  height: 1080px !important;
  background: #ffffff;
  overflow: hidden;
  position: relative; }
  .parallax-gallery .parallax-gallery-inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; }
    .parallax-gallery .parallax-gallery-inner .jarallax-img {
      will-change: transform; }
      .parallax-gallery .parallax-gallery-inner .jarallax-img img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%); }
    .parallax-gallery .parallax-gallery-inner .active {
      opacity: 1 !important; }
  .parallax-gallery .jarallax {
    position: relative;
    z-index: 0; }
  .parallax-gallery .jarallax .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
    .parallax-gallery .jarallax .jarallax-img img {
      min-width: 100%;
      min-height: 100%; }
  .parallax-gallery .teaser-texte {
    position: absolute;
    top: 40%;
    transform: translate(-50px, -50%);
    margin-top: 100px;
    left: 50px;
    opacity: 0;
    font-size: 300px;
    line-height: 230px;
    color: #fff;
    animation: aniTeaserText 2s forwards;
    animation-delay: 1.5s;
    animation-fill-mode: forwards; }

@keyframes aniTeaserText {
  0% {
    opacity: 0;
    transform: translate(-50px, -50%); }
  100% {
    opacity: 1;
    transform: translate(0px, -50%); } }

.parallax-type-gallery {
  max-height: 450px !important; }

@media (max-width: 1199.98px) and (min-width: 576px) {
  .parallax-gallery {
    height: 600px !important; }
    .parallax-gallery .teaser-texte {
      font-size: 180px;
      line-height: 135px; } }

@media (max-width: 767.98px) {
  .parallax-gallery .teaser-texte {
    font-size: 100px;
    line-height: 90px; }
  .parallax-type-gallery .teaser-texte {
    font-size: 80px;
    line-height: 70px;
    top: 30%; } }

.bildmittext-box .row .bildbox {
  position: relative; }
  .bildmittext-box .row .bildbox picture {
    max-width: 100%; }
    .bildmittext-box .row .bildbox picture img {
      max-width: 100%; }

.bildmittext-box picture {
  max-width: 100%; }
  .bildmittext-box picture img {
    max-width: 100%;
    box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.5);
    margin-bottom: 20px; }

@media (max-width: 991.98px) {
  .bildmittext-box {
    width: calc(100% - 20px);
    margin-left: 10px !important; } }

footer .comp_info a:hover {
  color: #f07d00 !important; }

.seminare-boxen {
  display: flex;
  flex-direction: row;
  position: relative; }
  .seminare-boxen .seminar-box {
    width: calc(100% / 3 - 20px);
    margin: 10px;
    position: relative; }
    .seminare-boxen .seminar-box .seminar-teaser {
      position: relative;
      width: 100%;
      box-shadow: 0px 0px 10px rgba(100, 100, 100, 0.5);
      margin-bottom: 20px; }
      .seminare-boxen .seminar-box .seminar-teaser picture img {
        width: 100%;
        height: auto; }
    .seminare-boxen .seminar-box .seminar-headline {
      position: relative;
      width: 100%;
      text-align: center;
      font-size: 30px;
      background-size: cover !important; }
    .seminare-boxen .seminar-box .seminar-subheadline {
      text-align: center; }
    .seminare-boxen .seminar-box .seminar-programmdauer {
      text-align: center; }

.shortcuts {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center; }
  .shortcuts .shortcut a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    opacity: 1;
    transition: 0.3s; }
  .shortcuts .shortcut a:hover {
    opacity: 0.75; }
  .shortcuts .shortcut h6 {
    color: #f07d00;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 400;
    margin-top: 10px; }
  .shortcuts .shortcut .shortcut--icon {
    width: 100px;
    height: 100px;
    position: relative;
    left: 50%;
    transform: translateX(-50%); }
    .shortcuts .shortcut .shortcut--icon svg {
      color: #f07d00; }
      .shortcuts .shortcut .shortcut--icon svg .st0 {
        fill: #f07d00; }

.hintergrundelement {
  position: absolute;
  left: 0px;
  bottom: 0px; }
  .hintergrundelement img, .hintergrundelement svg {
    width: calc(100vw / 16); }

.bild-box {
  width: 100%;
  position: relative;
  height: calc(100vw / 2 / 16 * 12); }
  .bild-box .bild-box-single {
    position: absolute;
    height: 100%;
    opacity: 0;
    left: 100%;
    top: 0px;
    transition: all 1.5s;
    width: 100%; }
    .bild-box .bild-box-single .row {
      height: 100%; }
      .bild-box .bild-box-single .row .bild h1 {
        position: absolute;
        right: 20px;
        color: #ffffff;
        max-width: 50% !important;
        margin-top: 30px !important;
        text-align: right;
        z-index: 999;
        text-shadow: none !important; }
      .bild-box .bild-box-single .row .bild .bilddetail {
        opacity: 0;
        transition: all 0.6s; }
        .bild-box .bild-box-single .row .bild .bilddetail picture {
          width: 100%;
          display: flex;
          position: absolute;
          top: 0px;
          left: 0px;
          height: 100%; }
          .bild-box .bild-box-single .row .bild .bilddetail picture img {
            width: 100%;
            height: calc(100vw / 2 / 16 * 12); }
      .bild-box .bild-box-single .row .bild .bullits {
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center; }
        .bild-box .bild-box-single .row .bild .bullits .bullit {
          background: #133157;
          position: relative;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          margin-right: 5px;
          margin-left: 5px;
          box-shadow: 0px 0px 5px rgba(100, 100, 100, 0.3);
          cursor: pointer; }
        .bild-box .bild-box-single .row .bild .bullits .show {
          background: #fff; }
      .bild-box .bild-box-single .row .box {
        background: #ffffff;
        position: absolute;
        left: 50%;
        transition: left 1s;
        height: 100%; }
        .bild-box .bild-box-single .row .box h2 {
          margin-top: 55px !important;
          text-align: left !important; }
  .bild-box .showPicDetail {
    opacity: 1 !important; }
  .bild-box .show {
    opacity: 1 !important;
    left: 0% !important; }
  .bild-box .hideLeft {
    left: -100% !important; }
    .bild-box .hideLeft .box {
      left: 10% !important; }
  .bild-box .arrow_right {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 100px;
    height: 100px;
    font-size: 80px;
    font-weight: bold;
    cursor: pointer; }
    .bild-box .arrow_right img, .bild-box .arrow_right svg {
      position: absolute;
      bottom: 10px;
      right: 10px;
      transition: opacity 0.3s; }
    .bild-box .arrow_right img:hover {
      opacity: 0.7; }

.bild-box {
  width: calc(100% + 20px);
  margin-left: -20px; }
  .bild-box .bild-box-single .row .box h2 {
    margin-top: 48px !important; }

@media (max-width: 1199.98px) {
  .bild-box {
    width: calc(100% + 20px);
    margin-left: -20px; }
    .bild-box .bild-box-single .row .box h2 {
      margin-top: 48px !important; }
    .bild-box .arrow_right img, .bild-box .arrow_right svg {
      height: 100px; } }

@media (max-width: 991.98px) {
  .bild-box {
    height: calc(100vw * 2); }
    .bild-box .bild-box-single .row {
      width: 100%;
      margin-left: 20px; }
      .bild-box .bild-box-single .row .bild {
        width: 100%;
        padding: 0px !important;
        height: 50%; }
        .bild-box .bild-box-single .row .bild .bilddetail picture img {
          height: 100vw; }
      .bild-box .bild-box-single .row .box {
        top: 50%;
        height: 50%;
        left: 20px !important;
        padding-left: 20px !important;
        padding-right: 20px !important; }
    .bild-box .arrow_right {
      bottom: 50%;
      text-shadow: 0px 0px 2px #fff; }
      .bild-box .arrow_right img, .bild-box .arrow_right svg {
        bottom: -50%;
        height: 100px; } }

.cat-listing {
  margin-top: 10px;
  margin-left: -15px !important;
  margin-right: -15px !important;
  margin-bottom: 40px;
  width: calc(100% + 30px) !important;
  justify-content: center; }
  .cat-listing div a {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 25px 10px;
    background: #fff;
    color: #f07d00;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
    text-align: center;
    transition: all 0.3s;
    font-size: 1.4rem;
    align-items: center;
    min-height: 270px;
    justify-content: flex-start; }
    .cat-listing div a .cat-listing-headline {
      min-height: 68px; }
    .cat-listing div a .cat-listing-text {
      font-size: 14px;
      min-height: 85px; }
    .cat-listing div a .cat-listing-devider {
      width: 100%; }
      .cat-listing div a .cat-listing-devider hr {
        width: 100%; }
      .cat-listing div a .cat-listing-devider .fontawsome-icon img, .cat-listing div a .cat-listing-devider .fontawsome-icon svg {
        fill: #a69d8a;
        color: #a69d8a;
        width: 30px; }
  .cat-listing div a:hover {
    color: #f07d00;
    opacity: 0.7; }

@media (max-width: 991.98px) {
  .cat-listing {
    margin-top: 10px;
    margin-bottom: 40px;
    margin-left: 10px !important;
    width: calc(100% - 20px) !important; } }

.kalender-startseite {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: flex-start;
  margin: 60px 0 20px;
  width: 100%;
  background: #fff;
  padding-left: 0px;
  padding-top: 0px;
  padding-right: 0px; }
  .kalender-startseite .kalender-item {
    width: 100%;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }
  .kalender-startseite .kalender-item:nth-last-child(1) {
    border-bottom: none; }
  .kalender-startseite .kalender--datum {
    font-size: 18px !important;
    font-family: "PT Sans";
    text-shadow: none !important;
    color: #fff !important;
    height: 25px;
    font-weight: 600;
    background: #9bb600 !important;
    text-align: center; }
  .kalender-startseite .kalender--titel {
    font-size: 18px !important;
    margin-top: 5px;
    font-family: "PT Sans";
    text-shadow: none !important;
    color: #0886c3 !important;
    text-align: center; }
  .kalender-startseite .kalender--text {
    color: #333333;
    overflow: hidden;
    position: relative;
    height: auto;
    max-height: 49px; }
    .kalender-startseite .kalender--text .kalender-text-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 32%, white 92%); }
    .kalender-startseite .kalender--text .kalender--text-overlay {
      position: absolute;
      left: 0px;
      top: 0px;
      height: 100%;
      width: 100%;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0) 32%, white 92%); }
  .kalender-startseite hr {
    height: 1px !important;
    background: rgba(255, 255, 255, 0.2) !important;
    border-top: 1px solid rgba(0, 0, 0, 0.2) !important;
    width: 343px;
    transform: rotate(-1deg);
    margin-top: 0px;
    position: absolute;
    bottom: 73px; }
  .kalender-startseite .kalender--icon {
    position: absolute;
    width: 50px;
    right: 20px;
    bottom: 66px; }
    .kalender-startseite .kalender--icon svg {
      fill: #006d45; }

#newsbilder {
  overflow: hidden;
  margin-left: -20px;
  margin-right: -20px; }
  #newsbilder .newsbilder-bild {
    width: 100%;
    height: 100%; }
    #newsbilder .newsbilder-bild picture img {
      width: 100%;
      height: auto; }

.kalender-uebersicht {
  max-width: 422px;
  position: relative;
  padding-bottom: 30px; }

.simplecalenderHeadline {
  font-size: 36px;
  margin-top: 6px !important; }

.SimpleCalendar thead tr th {
  border: 1px solid #fff;
  width: 60px;
  height: 60px;
  background: #565656;
  text-align: center;
  color: #fff; }

.SimpleCalendar tbody tr td {
  border: 1px solid #fff;
  width: 60px;
  height: 60px;
  background: #e1e1e1;
  text-align: center;
  color: #666;
  position: relative; }

.SimpleCalendar tbody tr .SCEMPTYDATE {
  background: transparent !important; }

.SimpleCalendar tbody tr .today {
  font-weight: bold; }

.SimpleCalendar tbody tr .hasDate {
  background: #f07d00;
  color: #fff; }

.SimpleCalendar tbody tr .eventover {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  position: absolute; }

.kalender-listing .eintrag {
  padding-bottom: 10px;
  padding-top: 10px;
  transition: all 0.5s;
  border-bottom: 1px solid #e5e5e5; }
  .kalender-listing .eintrag .datum {
    font-size: 24px;
    font-weight: bold;
    color: #f07d00; }
  .kalender-listing .eintrag .titel {
    font-size: 24px; }
  .kalender-listing .eintrag .text {
    margin-left: 15px; }

.eintrag-highlight {
  background: rgba(0, 0, 0, 0.1); }

#scPrev, #scNext {
  position: absolute;
  width: 60px;
  height: 60px;
  top: 0px; }
  #scPrev i img, #scPrev i svg, #scNext i img, #scNext i svg {
    width: 40px;
    height: 40px;
    margin-bottom: 20px; }

#scPrev {
  left: 0px;
  margin-right: 20px;
  display: flex;
  justify-content: center; }

#scNext {
  right: 0px;
  margin-left: 20px;
  display: flex;
  justify-content: center; }

.klassen-listing .klasse {
  padding: 20px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center; }
  .klassen-listing .klasse .klassen-box {
    background: #bed63f;
    font-size: 21px;
    color: #fff;
    font-weight: bold;
    padding: 2px;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 40px;
    line-height: 35px;
    height: 40px;
    text-align: center;
    margin-bottom: 20px; }

.downloads .download {
  margin-bottom: 40px; }
  .downloads .download .link-text {
    height: 50px;
    display: flex;
    justify-content: center;
    flex-direction: column; }

.downloads a {
  color: #f07d00 !important;
  transition: all 0.3s; }
  .downloads a img, .downloads a svg {
    color: #f07d00 !important;
    fill: #f07d00 !important;
    transition: all 0.3s;
    height: 50px;
    width: 50px; }

.downloads a:hover {
  color: #f3f3f3 !important; }
  .downloads a:hover img, .downloads a:hover svg {
    color: #f3f3f3 !important;
    fill: #f3f3f3 !important; }

.scrollblock .scrollblock-chapter-logo picture {
  max-width: 100vw; }
  .scrollblock .scrollblock-chapter-logo picture img {
    width: 100% !important; }

main .fadeing .row {
  transition: opacity 1s ease-in-out 0s, transform 0.6s ease-in-out 0s;
  transform: translateY(30px); }

main .fadeContentIn {
  opacity: 1 !important; }
  main .fadeContentIn .row {
    transform: none !important; }

html, body {
  overscroll-behavior-y: none;
  text-rendering: geometricPrecision; }
  html .container-fluid, html .container-xs, html .container-sm, html .container-md, html .container-lg, html .container-xl, body .container-fluid, body .container-xs, body .container-sm, body .container-md, body .container-lg, body .container-xl {
    overflow: hidden; }

body ::selection {
  text-shadow: none;
  background: #f07d00;
  color: #eee; }

body header {
  position: fixed !important;
  top: 0px;
  z-index: 999;
  background: none !important;
  height: 125px !important; }
  body header #header-dynamic-background {
    opacity: 0.9;
    background: #fff; }
  body header .schule-fuer-alle {
    height: 40px;
    width: 160px;
    position: absolute;
    top: 105px;
    left: 90px;
    transform: translateX(-50%);
    z-index: 999999; }
    body header .schule-fuer-alle img, body header .schule-fuer-alle svg {
      height: 100% !important;
      width: 100% !important;
      top: unset !important;
      left: unset !important;
      position: unset !important;
      margin-left: 110px; }
  body header #page-settings {
    position: absolute;
    right: 20px;
    top: 40px;
    display: flex;
    flex-direction: row;
    font-size: 12px; }
    body header #page-settings .devider {
      width: 1px;
      height: 65px;
      margin-left: 10px;
      margin-right: 10px;
      border-left: 1px solid #736d5f; }
    body header #page-settings .btn-settings {
      justify-content: center;
      display: flex;
      flex-direction: column;
      text-align: center; }
      body header #page-settings .btn-settings .btn-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: center;
        justify-items: center; }
      body header #page-settings .btn-settings i {
        display: flex;
        justify-items: center;
        justify-content: center;
        margin-right: 10px; }
        body header #page-settings .btn-settings i img, body header #page-settings .btn-settings i svg {
          color: #736d5f;
          fill: #736d5f !important; }
          body header #page-settings .btn-settings i img .st0, body header #page-settings .btn-settings i svg .st0 {
            fill: #736d5f !important; }
      body header #page-settings .btn-settings img, body header #page-settings .btn-settings svg {
        width: 40px; }
  body header #header-silhouette {
    position: relative;
    background: #9bb600;
    border-bottom: 20px solid #bed63f;
    height: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center; }
    body header #header-silhouette #logo {
      position: relative;
      height: 100%; }
    body header #header-silhouette img, body header #header-silhouette svg {
      position: absolute;
      left: 10px;
      top: 0px;
      z-index: 999999;
      width: 368px; }
  body header #logo a picture img {
    margin-top: 15px;
    height: 100px;
    transform: translateX(-50%) !important; }
  body header #navigation {
    margin-top: 0px; }
    body header #navigation .navbar-toggler {
      top: 42px;
      left: 50%;
      transform: translateX(-368px);
      width: 60px;
      border-left: 1px solid #565656;
      border-radius: 0px !important; }
      body header #navigation .navbar-toggler .navbar-toggler-icon {
        width: 60px;
        height: 60px; }
    body header #navigation nav #navbarSupportedContent {
      left: 50%;
      margin-left: -556px;
      max-width: 343px;
      transform: translateX(-50%);
      margin-top: -13px;
      margin-right: 0px;
      position: relative;
      background: transparent !important; }
      body header #navigation nav #navbarSupportedContent .navbar-nav {
        justify-content: space-between;
        align-content: center;
        display: flex;
        flex-direction: column;
        width: 100%; }
        body header #navigation nav #navbarSupportedContent .navbar-nav .nav-item {
          display: flex;
          background: transparent;
          width: 100%;
          text-transform: uppercase;
          justify-content: center;
          flex-direction: column;
          align-self: flex-start; }
          body header #navigation nav #navbarSupportedContent .navbar-nav .nav-item .nav-link {
            color: #fff;
            width: 100%;
            text-align: center;
            z-index: 999;
            font-family: "Eurostile";
            letter-spacing: 1px;
            font-size: 2rem; }
          body header #navigation nav #navbarSupportedContent .navbar-nav .nav-item .dropdown-menu {
            width: 100%;
            z-index: 999;
            padding-top: 0px;
            margin-top: 0px; }
            body header #navigation nav #navbarSupportedContent .navbar-nav .nav-item .dropdown-menu .dropdown-item {
              color: #fff;
              font-weight: normal;
              text-transform: none; }
            body header #navigation nav #navbarSupportedContent .navbar-nav .nav-item .dropdown-menu .dropdown-menu-list {
              flex-direction: column;
              align-content: center; }
        body header #navigation nav #navbarSupportedContent .navbar-nav .nav-item {
          margin-top: 2px !important; }
          body header #navigation nav #navbarSupportedContent .navbar-nav .nav-item .nav-link {
            color: #565656;
            background: #f3f3f3;
            height: 60px;
            transition: all 0.3s;
            text-shadow: none !important;
            font-weight: 400 !important; }
          body header #navigation nav #navbarSupportedContent .navbar-nav .nav-item .nav-link:hover {
            color: #f07d00 !important;
            opacity: 0.9; }
      body header #navigation nav #navbarSupportedContent .shd-box-main .shd-box:before {
        left: 12px !important;
        box-shadow: 0 12px 15px rgba(100, 100, 100, 0.8) !important;
        width: 50%; }
      body header #navigation nav #navbarSupportedContent .shd-box-main .shd-box:after {
        content: " ";
        box-shadow: 0 12px 15px rgba(100, 100, 100, 0.8);
        height: 150px;
        transform: rotate(2deg);
        width: 50%;
        top: -100%;
        right: 12px;
        position: absolute;
        transform-origin: bottom right; }
      body header #navigation nav #navbarSupportedContent .dropdown-menu {
        display: none;
        top: 0px !important;
        padding-top: 0px !important;
        left: 0px;
        z-index: 1; }
        body header #navigation nav #navbarSupportedContent .dropdown-menu .dropdown-menu-list {
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          flex-wrap: wrap;
          padding-top: 30px;
          padding-bottom: 20px; }
          body header #navigation nav #navbarSupportedContent .dropdown-menu .dropdown-menu-list .dropdown-item {
            max-width: 250px;
            min-width: calc(25% - 40px);
            margin-left: 20px;
            margin-right: 20px;
            text-align: center;
            margin-bottom: 10px;
            font-size: 1.3rem; }
      body header #navigation nav #navbarSupportedContent .show {
        display: flex; }
  body header #header-icon {
    height: 65px;
    width: 150px;
    right: 10px;
    top: 30px;
    position: absolute;
    flex-direction: row;
    padding-top: 17px;
    padding-right: 20px; }
    body header #header-icon a {
      margin-top: auto;
      margin-right: 10px;
      margin-bottom: auto; }
      body header #header-icon a i svg, body header #header-icon a i img {
        height: 30px;
        color: #565656; }

body h1 {
  -webkit-font-smoothing: antialiased;
  margin-bottom: 15px;
  text-transform: uppercase; }

body h2 {
  margin-bottom: 20px; }

body main {
  padding-top: 125px; }

body .bg-primary .kontakticons a, body .bg-secondary .kontakticons a, body .bg-hellblau .kontakticons a, body .bg-dunkelblau .kontakticons a {
  transition: opacity 0.3s;
  color: #565656 !important; }
  body .bg-primary .kontakticons a i img, body .bg-primary .kontakticons a i svg, body .bg-secondary .kontakticons a i img, body .bg-secondary .kontakticons a i svg, body .bg-hellblau .kontakticons a i img, body .bg-hellblau .kontakticons a i svg, body .bg-dunkelblau .kontakticons a i img, body .bg-dunkelblau .kontakticons a i svg {
    fill: #565656 !important;
    color: #565656 !important;
    transition: color 0.3s;
    opacity: 1; }

body .bg-primary .kontakticons a:hover i img, body .bg-primary .kontakticons a:hover i svg, body .bg-secondary .kontakticons a:hover i img, body .bg-secondary .kontakticons a:hover i svg, body .bg-hellblau .kontakticons a:hover i img, body .bg-hellblau .kontakticons a:hover i svg, body .bg-dunkelblau .kontakticons a:hover i img, body .bg-dunkelblau .kontakticons a:hover i svg {
  opacity: 0.9; }

body .bg-primary .downloads a, body .bg-secondary .downloads a, body .bg-hellblau .downloads a, body .bg-dunkelblau .downloads a {
  color: #fff !important; }
  body .bg-primary .downloads a img, body .bg-primary .downloads a svg, body .bg-secondary .downloads a img, body .bg-secondary .downloads a svg, body .bg-hellblau .downloads a img, body .bg-hellblau .downloads a svg, body .bg-dunkelblau .downloads a img, body .bg-dunkelblau .downloads a svg {
    color: #fff !important;
    fill: #fff !important; }

body .bg-primary .downloads a:hover, body .bg-secondary .downloads a:hover, body .bg-hellblau .downloads a:hover, body .bg-dunkelblau .downloads a:hover {
  color: rgba(255, 255, 255, 0.5) !important; }
  body .bg-primary .downloads a:hover img, body .bg-primary .downloads a:hover svg, body .bg-secondary .downloads a:hover img, body .bg-secondary .downloads a:hover svg, body .bg-hellblau .downloads a:hover img, body .bg-hellblau .downloads a:hover svg, body .bg-dunkelblau .downloads a:hover img, body .bg-dunkelblau .downloads a:hover svg {
    color: rgba(255, 255, 255, 0.5) !important;
    fill: rgba(255, 255, 255, 0.5) !important; }

body .bg-primary {
  background-color: #f07d00 !important;
  color: #f3f3f3 !important; }
  body .bg-primary h1 {
    text-shadow: -1px -1px rgba(0, 0, 0, 0.3);
    color: #ebff41; }
  body .bg-primary h2 {
    color: #ebff41; }
  body .bg-primary hr {
    border-color: #ebff41 !important;
    opacity: 0.2; }
  body .bg-primary .cat-listing svg, body .bg-primary .cat-listing img {
    fill: #565656;
    color: #565656; }
  body .bg-primary .cat-listing hr {
    border-color: #fff !important; }
  body .bg-primary .cat-listing a {
    color: #565656; }
  body .bg-primary .cat-listing a:hover {
    color: #565656; }

body .bg-secondary {
  background-color: #565656 !important;
  color: #fff; }
  body .bg-secondary h1 {
    text-shadow: -1px -1px rgba(0, 0, 0, 0.3);
    color: #fff; }
  body .bg-secondary h2 {
    color: #fff; }
  body .bg-secondary hr {
    border-color: #fff !important;
    opacity: 0.2; }
  body .bg-secondary .cat-listing hr {
    border-color: #a9a9a9 !important;
    opacity: 0.3; }
  body .bg-secondary .cat-listing svg, body .bg-secondary .cat-listing img {
    fill: #a9a9a9;
    color: #a9a9a9; }
  body .bg-secondary .cat-listing a {
    color: #a9a9a9; }
  body .bg-secondary .cat-listing a:hover {
    color: #a9a9a9; }

body .bg-hellblau {
  background-color: #a9a9a9 !important;
  color: #fff; }
  body .bg-hellblau h1 {
    text-shadow: -1px -1px rgba(0, 0, 0, 0.3);
    color: #fff; }
  body .bg-hellblau h2 {
    color: #fff; }
  body .bg-hellblau hr {
    border-color: rgba(255, 255, 255, 0.25) !important; }
  body .bg-hellblau .cat-listing hr {
    border-color: #a9a9a9 !important;
    opacity: 0.3; }
  body .bg-hellblau .cat-listing svg, body .bg-hellblau .cat-listing img {
    fill: #a9a9a9;
    color: #a9a9a9; }
  body .bg-hellblau .cat-listing a {
    color: #a9a9a9; }
  body .bg-hellblau .cat-listing a:hover {
    color: #a9a9a9; }

body .bg-dunkelblau {
  background-color: #f3f3f3 !important;
  color: #565656; }
  body .bg-dunkelblau h1 {
    text-shadow: 1px 1px white;
    color: #f07d00 !important; }
  body .bg-dunkelblau h2 {
    color: #565656 !important; }
  body .bg-dunkelblau hr {
    border-color: #fff !important; }
  body .bg-dunkelblau .cat-listing hr {
    border-color: #a9a9a9 !important;
    opacity: 0.3; }
  body .bg-dunkelblau .cat-listing svg, body .bg-dunkelblau .cat-listing img {
    fill: #a9a9a9;
    color: #a9a9a9; }
  body .bg-dunkelblau .cat-listing a {
    color: #a9a9a9; }
  body .bg-dunkelblau .cat-listing a:hover {
    color: #a9a9a9; }

body .bg-success {
  background-color: #565656;
  color: #000; }
  body .bg-success .cat-listing hr {
    border-color: #a9a9a9 !important;
    opacity: 0.3; }
  body .bg-success .cat-listing svg, body .bg-success .cat-listing img {
    fill: #f3f3f3;
    color: #f3f3f3; }
  body .bg-success .cat-listing a {
    color: #f3f3f3; }
  body .bg-success .cat-listing a:hover {
    color: #f3f3f3; }

body footer {
  color: #fff !important; }
  body footer .footer {
    border-top-color: #ebff41 !important;
    background: #a9a9a9 !important;
    color: #fff !important;
    border-bottom: 40px solid #ebff41 !important; }
    body footer .footer a {
      color: #fff !important; }
  body footer a {
    color: #fff !important;
    transition: all 0.3s; }
  body footer .footer-logo-text {
    background: #0886c3;
    font-size: 25px;
    text-transform: uppercase; }
    body footer .footer-logo-text .footer-logo-text-schule {
      font-family: "Eurostile";
      font-size: 30px;
      font-weight: 600; }
  body footer a:hover {
    color: rgba(255, 255, 255, 0.8) !important; }
  body footer .w-25 {
    width: 30% !important; }
  body footer h2 {
    color: #fff;
    font-size: 30px !important;
    margin-bottom: 10px !important;
    font-weight: 600 !important;
    font-synthesis-weight: none !important; }
  body footer .tinyMCE table {
    max-width: 180px; }
  body footer .kontakticons-full-detail {
    text-align: center;
    top: -31px;
    position: relative; }
    body footer .kontakticons-full-detail div {
      position: relative; }

body .pagination .page-link {
  color: #565656; }

body .pagination .active .page-link {
  background-color: #565656;
  border-color: #565656; }

.site-map--lev1 li:nth-child(1) {
  background: #f07d00; }
  .site-map--lev1 li:nth-child(1) h5 a {
    color: #736d5f; }
  .site-map--lev1 li:nth-child(1) ul li a {
    color: #736d5f !important; }

.site-map--lev1 li:nth-child(2) {
  background: #565656; }

.site-map--lev1 li:nth-child(3) {
  background: #a9a9a9; }

.site-map--lev1 li:nth-child(4) {
  background: #f3f3f3; }

.site-map--lev1 .site-map--lev2 li {
  background: transparent; }

.kontakt-bild {
  width: 150px;
  height: 100%;
  float: left;
  margin-right: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); }
  .kontakt-bild picture {
    position: relative;
    width: 100%;
    height: 100%; }
    .kontakt-bild picture img {
      position: relative;
      width: 100%;
      height: 100%; }

.teaser--fixed-text {
  position: fixed;
  bottom: 20px;
  font-size: 60px;
  left: 50%;
  transform: translateX(-50%);
  font-family: "Eurostile";
  font-weight: 400;
  text-align: center;
  border-bottom: 1px solid #ffffff;
  color: #ffffff; }

b, strong {
  font-weight: 700 !important; }

.klassen-listing li {
  margin-left: 20px; }

.news-startseite h3 {
  color: #f07d00;
  transition: color 0.3s; }

a:hover .news-startseite h3 {
  color: #565656; }

.comp1-data {
  text-align: center; }

:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --secondary: #0000ff;
  --primary: #eb2323;
  --primary-dark: #990000;
  --primary-light: #990000;
  --primary-light-hover: #00ff00;
  --btn-primary-text-hover: #fff;
  --btn-primary-text: #212529;
  --btn-primary-bg: #ffa401;
  --body-bg-hover: #e0e0e0;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.3rem !important;
  font-weight: 200;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  background-color: #fff; }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b, strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #e0e0e0;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0056b3;
    text-decoration: underline; }

a:not([href]):not([class]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit;
  text-align: -webkit-match-parent; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus:not(:focus-visible) {
  outline: 0; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

[role="button"] {
  cursor: pointer; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 200;
  line-height: 1.2; }

h1, .h1 {
  font-size: 2.5rem; }

h2, .h2 {
  font-size: 2rem; }

h3, .h3 {
  font-size: 1.75rem; }

h4, .h4 {
  font-size: 1.5rem; }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.2; }

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.2; }

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.2; }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1); }

small,
.small {
  font-size: 80%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.container,
.container-fluid,
.container-xs,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

.container {
  max-width: 540px; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 720px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 960px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 1140px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1500px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6; }
  .table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6; }
  .table tbody + tbody {
    border-top: 2px solid #dee2e6; }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid #dee2e6; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6; }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #212529;
  background-color: rgba(0, 0, 0, 0.075); }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #b8b8ff; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7a7aff; }

.table-hover .table-secondary:hover {
  background-color: #9f9fff; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #9f9fff; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #f9c1c1; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #f58d8d; }

.table-hover .table-primary:hover {
  background-color: #f7aaaa; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #f7aaaa; }

.table-primary-dark,
.table-primary-dark > th,
.table-primary-dark > td {
  background-color: #e2b8b8; }

.table-primary-dark th,
.table-primary-dark td,
.table-primary-dark thead th,
.table-primary-dark tbody + tbody {
  border-color: #ca7a7a; }

.table-hover .table-primary-dark:hover {
  background-color: #dba6a6; }
  .table-hover .table-primary-dark:hover > td,
  .table-hover .table-primary-dark:hover > th {
    background-color: #dba6a6; }

.table-primary-light,
.table-primary-light > th,
.table-primary-light > td {
  background-color: #e2b8b8; }

.table-primary-light th,
.table-primary-light td,
.table-primary-light thead th,
.table-primary-light tbody + tbody {
  border-color: #ca7a7a; }

.table-hover .table-primary-light:hover {
  background-color: #dba6a6; }
  .table-hover .table-primary-light:hover > td,
  .table-hover .table-primary-light:hover > th {
    background-color: #dba6a6; }

.table-primary-light-hover,
.table-primary-light-hover > th,
.table-primary-light-hover > td {
  background-color: #b8ffb8; }

.table-primary-light-hover th,
.table-primary-light-hover td,
.table-primary-light-hover thead th,
.table-primary-light-hover tbody + tbody {
  border-color: #7aff7a; }

.table-hover .table-primary-light-hover:hover {
  background-color: #9fff9f; }
  .table-hover .table-primary-light-hover:hover > td,
  .table-hover .table-primary-light-hover:hover > th {
    background-color: #9fff9f; }

.table-btn-primary-text-hover,
.table-btn-primary-text-hover > th,
.table-btn-primary-text-hover > td {
  background-color: white; }

.table-btn-primary-text-hover th,
.table-btn-primary-text-hover td,
.table-btn-primary-text-hover thead th,
.table-btn-primary-text-hover tbody + tbody {
  border-color: white; }

.table-hover .table-btn-primary-text-hover:hover {
  background-color: #f2f2f2; }
  .table-hover .table-btn-primary-text-hover:hover > td,
  .table-hover .table-btn-primary-text-hover:hover > th {
    background-color: #f2f2f2; }

.table-btn-primary-text,
.table-btn-primary-text > th,
.table-btn-primary-text > td {
  background-color: #c1c2c3; }

.table-btn-primary-text th,
.table-btn-primary-text td,
.table-btn-primary-text thead th,
.table-btn-primary-text tbody + tbody {
  border-color: #8c8e90; }

.table-hover .table-btn-primary-text:hover {
  background-color: #b4b5b6; }
  .table-hover .table-btn-primary-text:hover > td,
  .table-hover .table-btn-primary-text:hover > th {
    background-color: #b4b5b6; }

.table-btn-primary-bg,
.table-btn-primary-bg > th,
.table-btn-primary-bg > td {
  background-color: #ffe6b8; }

.table-btn-primary-bg th,
.table-btn-primary-bg td,
.table-btn-primary-bg thead th,
.table-btn-primary-bg tbody + tbody {
  border-color: #ffd07b; }

.table-hover .table-btn-primary-bg:hover {
  background-color: #ffdd9f; }
  .table-hover .table-btn-primary-bg:hover > td,
  .table-hover .table-btn-primary-bg:hover > th {
    background-color: #ffdd9f; }

.table-body-bg-hover,
.table-body-bg-hover > th,
.table-body-bg-hover > td {
  background-color: #f6f6f6; }

.table-body-bg-hover th,
.table-body-bg-hover td,
.table-body-bg-hover thead th,
.table-body-bg-hover tbody + tbody {
  border-color: #efefef; }

.table-hover .table-body-bg-hover:hover {
  background-color: #e9e9e9; }
  .table-hover .table-body-bg-hover:hover > td,
  .table-hover .table-body-bg-hover:hover > th {
    background-color: #e9e9e9; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #e9ecef;
  border-color: #dee2e6; }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057; }
  .form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .form-control::placeholder {
    color: #6c757d;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

input[type="date"].form-control,
input[type="time"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control {
  appearance: none; }

select.form-control:focus::-ms-value {
  color: #495057;
  background-color: #fff; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
  line-height: 1.5; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #6c757d; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .valid-tooltip,
  .form-row > [class*="col-"] > .valid-tooltip {
    left: 5px; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #28a745; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem; }
  .form-row > .col > .invalid-tooltip,
  .form-row > [class*="col-"] > .invalid-tooltip {
    left: 5px; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") right 0.75rem center/8px 10px no-repeat, #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e") center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) no-repeat; }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #212529;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-secondary {
  color: #fff;
  background-color: #0000ff;
  border-color: #0000ff; }
  .btn-secondary:hover {
    color: #fff;
    background-color: #0000d9;
    border-color: #0000cc; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #fff;
    background-color: #0000d9;
    border-color: #0000cc;
    box-shadow: 0 0 0 0.2rem rgba(38, 38, 255, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #0000ff;
    border-color: #0000ff; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0000cc;
    border-color: #0000bf; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 38, 255, 0.5); }

.btn-primary {
  color: #fff;
  background-color: #eb2323;
  border-color: #eb2323; }
  .btn-primary:hover {
    color: #fff;
    background-color: #d41313;
    border-color: #c91212; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #d41313;
    border-color: #c91212;
    box-shadow: 0 0 0 0.2rem rgba(238, 68, 68, 0.5); }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #eb2323;
    border-color: #eb2323; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #c91212;
    border-color: #bd1111; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(238, 68, 68, 0.5); }

.btn-primary-dark {
  color: #fff;
  background-color: #990000;
  border-color: #990000; }
  .btn-primary-dark:hover {
    color: #fff;
    background-color: #730000;
    border-color: #660000; }
  .btn-primary-dark:focus, .btn-primary-dark.focus {
    color: #fff;
    background-color: #730000;
    border-color: #660000;
    box-shadow: 0 0 0 0.2rem rgba(168, 38, 38, 0.5); }
  .btn-primary-dark.disabled, .btn-primary-dark:disabled {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
  .btn-primary-dark:not(:disabled):not(.disabled):active, .btn-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #660000;
    border-color: #590000; }
    .btn-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-primary-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 38, 38, 0.5); }

.btn-primary-light {
  color: #fff;
  background-color: #990000;
  border-color: #990000; }
  .btn-primary-light:hover {
    color: #fff;
    background-color: #730000;
    border-color: #660000; }
  .btn-primary-light:focus, .btn-primary-light.focus {
    color: #fff;
    background-color: #730000;
    border-color: #660000;
    box-shadow: 0 0 0 0.2rem rgba(168, 38, 38, 0.5); }
  .btn-primary-light.disabled, .btn-primary-light:disabled {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
  .btn-primary-light:not(:disabled):not(.disabled):active, .btn-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light.dropdown-toggle {
    color: #fff;
    background-color: #660000;
    border-color: #590000; }
    .btn-primary-light:not(:disabled):not(.disabled):active:focus, .btn-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(168, 38, 38, 0.5); }

.btn-primary-light-hover {
  color: #fff;
  background-color: #00ff00;
  border-color: #00ff00; }
  .btn-primary-light-hover:hover {
    color: #fff;
    background-color: #00d900;
    border-color: #00cc00; }
  .btn-primary-light-hover:focus, .btn-primary-light-hover.focus {
    color: #fff;
    background-color: #00d900;
    border-color: #00cc00;
    box-shadow: 0 0 0 0.2rem rgba(38, 255, 38, 0.5); }
  .btn-primary-light-hover.disabled, .btn-primary-light-hover:disabled {
    color: #fff;
    background-color: #00ff00;
    border-color: #00ff00; }
  .btn-primary-light-hover:not(:disabled):not(.disabled):active, .btn-primary-light-hover:not(:disabled):not(.disabled).active,
  .show > .btn-primary-light-hover.dropdown-toggle {
    color: #fff;
    background-color: #00cc00;
    border-color: #00bf00; }
    .btn-primary-light-hover:not(:disabled):not(.disabled):active:focus, .btn-primary-light-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary-light-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(38, 255, 38, 0.5); }

.btn-btn-primary-text-hover {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-btn-primary-text-hover:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6; }
  .btn-btn-primary-text-hover:focus, .btn-btn-primary-text-hover.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }
  .btn-btn-primary-text-hover.disabled, .btn-btn-primary-text-hover:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-btn-primary-text-hover:not(:disabled):not(.disabled):active, .btn-btn-primary-text-hover:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-text-hover.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #dfdfdf; }
    .btn-btn-primary-text-hover:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-text-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-text-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 222, 223, 0.5); }

.btn-btn-primary-text {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-btn-primary-text:hover {
    color: #fff;
    background-color: #101214;
    border-color: #0a0c0d; }
  .btn-btn-primary-text:focus, .btn-btn-primary-text.focus {
    color: #fff;
    background-color: #101214;
    border-color: #0a0c0d;
    box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }
  .btn-btn-primary-text.disabled, .btn-btn-primary-text:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-btn-primary-text:not(:disabled):not(.disabled):active, .btn-btn-primary-text:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-text.dropdown-toggle {
    color: #fff;
    background-color: #0a0c0d;
    border-color: #050506; }
    .btn-btn-primary-text:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(66, 70, 73, 0.5); }

.btn-btn-primary-bg {
  color: #212529;
  background-color: #ffa401;
  border-color: #ffa401; }
  .btn-btn-primary-bg:hover {
    color: #fff;
    background-color: #da8c00;
    border-color: #cd8400; }
  .btn-btn-primary-bg:focus, .btn-btn-primary-bg.focus {
    color: #fff;
    background-color: #da8c00;
    border-color: #cd8400;
    box-shadow: 0 0 0 0.2rem rgba(222, 145, 7, 0.5); }
  .btn-btn-primary-bg.disabled, .btn-btn-primary-bg:disabled {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
  .btn-btn-primary-bg:not(:disabled):not(.disabled):active, .btn-btn-primary-bg:not(:disabled):not(.disabled).active,
  .show > .btn-btn-primary-bg.dropdown-toggle {
    color: #fff;
    background-color: #cd8400;
    border-color: #c07b00; }
    .btn-btn-primary-bg:not(:disabled):not(.disabled):active:focus, .btn-btn-primary-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-btn-primary-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(222, 145, 7, 0.5); }

.btn-body-bg-hover {
  color: #212529;
  background-color: #e0e0e0;
  border-color: #e0e0e0; }
  .btn-body-bg-hover:hover {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c7c7; }
  .btn-body-bg-hover:focus, .btn-body-bg-hover.focus {
    color: #212529;
    background-color: #cdcdcd;
    border-color: #c7c7c7;
    box-shadow: 0 0 0 0.2rem rgba(195, 196, 197, 0.5); }
  .btn-body-bg-hover.disabled, .btn-body-bg-hover:disabled {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-body-bg-hover:not(:disabled):not(.disabled):active, .btn-body-bg-hover:not(:disabled):not(.disabled).active,
  .show > .btn-body-bg-hover.dropdown-toggle {
    color: #212529;
    background-color: #c7c7c7;
    border-color: silver; }
    .btn-body-bg-hover:not(:disabled):not(.disabled):active:focus, .btn-body-bg-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-body-bg-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(195, 196, 197, 0.5); }

.btn-outline-secondary {
  color: #0000ff;
  border-color: #0000ff; }
  .btn-outline-secondary:hover {
    color: #fff;
    background-color: #0000ff;
    border-color: #0000ff; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #0000ff;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #0000ff;
    border-color: #0000ff; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 0, 255, 0.5); }

.btn-outline-primary {
  color: #eb2323;
  border-color: #eb2323; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #eb2323;
    border-color: #eb2323; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0.2rem rgba(235, 35, 35, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #eb2323;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #eb2323;
    border-color: #eb2323; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(235, 35, 35, 0.5); }

.btn-outline-primary-dark {
  color: #990000;
  border-color: #990000; }
  .btn-outline-primary-dark:hover {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
  .btn-outline-primary-dark:focus, .btn-outline-primary-dark.focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 0, 0, 0.5); }
  .btn-outline-primary-dark.disabled, .btn-outline-primary-dark:disabled {
    color: #990000;
    background-color: transparent; }
  .btn-outline-primary-dark:not(:disabled):not(.disabled):active, .btn-outline-primary-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-dark.dropdown-toggle {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
    .btn-outline-primary-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 0, 0, 0.5); }

.btn-outline-primary-light {
  color: #990000;
  border-color: #990000; }
  .btn-outline-primary-light:hover {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
  .btn-outline-primary-light:focus, .btn-outline-primary-light.focus {
    box-shadow: 0 0 0 0.2rem rgba(153, 0, 0, 0.5); }
  .btn-outline-primary-light.disabled, .btn-outline-primary-light:disabled {
    color: #990000;
    background-color: transparent; }
  .btn-outline-primary-light:not(:disabled):not(.disabled):active, .btn-outline-primary-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light.dropdown-toggle {
    color: #fff;
    background-color: #990000;
    border-color: #990000; }
    .btn-outline-primary-light:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(153, 0, 0, 0.5); }

.btn-outline-primary-light-hover {
  color: #00ff00;
  border-color: #00ff00; }
  .btn-outline-primary-light-hover:hover {
    color: #fff;
    background-color: #00ff00;
    border-color: #00ff00; }
  .btn-outline-primary-light-hover:focus, .btn-outline-primary-light-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 255, 0, 0.5); }
  .btn-outline-primary-light-hover.disabled, .btn-outline-primary-light-hover:disabled {
    color: #00ff00;
    background-color: transparent; }
  .btn-outline-primary-light-hover:not(:disabled):not(.disabled):active, .btn-outline-primary-light-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary-light-hover.dropdown-toggle {
    color: #fff;
    background-color: #00ff00;
    border-color: #00ff00; }
    .btn-outline-primary-light-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-primary-light-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary-light-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(0, 255, 0, 0.5); }

.btn-outline-btn-primary-text-hover {
  color: #fff;
  border-color: #fff; }
  .btn-outline-btn-primary-text-hover:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-btn-primary-text-hover:focus, .btn-outline-btn-primary-text-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }
  .btn-outline-btn-primary-text-hover.disabled, .btn-outline-btn-primary-text-hover:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-text-hover.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-text-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-text-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5); }

.btn-outline-btn-primary-text {
  color: #212529;
  border-color: #212529; }
  .btn-outline-btn-primary-text:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-outline-btn-primary-text:focus, .btn-outline-btn-primary-text.focus {
    box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }
  .btn-outline-btn-primary-text.disabled, .btn-outline-btn-primary-text:disabled {
    color: #212529;
    background-color: transparent; }
  .btn-outline-btn-primary-text:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-text:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-text.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-btn-primary-text:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-text:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-text.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(33, 37, 41, 0.5); }

.btn-outline-btn-primary-bg {
  color: #ffa401;
  border-color: #ffa401; }
  .btn-outline-btn-primary-bg:hover {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
  .btn-outline-btn-primary-bg:focus, .btn-outline-btn-primary-bg.focus {
    box-shadow: 0 0 0 0.2rem rgba(255, 164, 1, 0.5); }
  .btn-outline-btn-primary-bg.disabled, .btn-outline-btn-primary-bg:disabled {
    color: #ffa401;
    background-color: transparent; }
  .btn-outline-btn-primary-bg:not(:disabled):not(.disabled):active, .btn-outline-btn-primary-bg:not(:disabled):not(.disabled).active,
  .show > .btn-outline-btn-primary-bg.dropdown-toggle {
    color: #212529;
    background-color: #ffa401;
    border-color: #ffa401; }
    .btn-outline-btn-primary-bg:not(:disabled):not(.disabled):active:focus, .btn-outline-btn-primary-bg:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-btn-primary-bg.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 164, 1, 0.5); }

.btn-outline-body-bg-hover {
  color: #e0e0e0;
  border-color: #e0e0e0; }
  .btn-outline-body-bg-hover:hover {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
  .btn-outline-body-bg-hover:focus, .btn-outline-body-bg-hover.focus {
    box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }
  .btn-outline-body-bg-hover.disabled, .btn-outline-body-bg-hover:disabled {
    color: #e0e0e0;
    background-color: transparent; }
  .btn-outline-body-bg-hover:not(:disabled):not(.disabled):active, .btn-outline-body-bg-hover:not(:disabled):not(.disabled).active,
  .show > .btn-outline-body-bg-hover.dropdown-toggle {
    color: #212529;
    background-color: #e0e0e0;
    border-color: #e0e0e0; }
    .btn-outline-body-bg-hover:not(:disabled):not(.disabled):active:focus, .btn-outline-body-bg-hover:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-body-bg-hover.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(224, 224, 224, 0.5); }

.btn-link {
  font-weight: 400;
  color: #e0e0e0;
  text-decoration: none; }
  .btn-link:hover {
    color: #0056b3;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline; }
  .btn-link:disabled, .btn-link.disabled {
    color: #6c757d;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }
  .input-group:not(.has-validation) > .form-control:not(:last-child),
  .input-group:not(.has-validation) > .custom-select:not(:last-child),
  .input-group:not(.has-validation) > .custom-file:not(:last-child) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group.has-validation > .form-control:nth-last-child(n + 3),
  .input-group.has-validation > .custom-select:nth-last-child(n + 3),
  .input-group.has-validation > .custom-file:nth-last-child(n + 3) .custom-file-label::after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .btn,
.input-group:not(.has-validation) > .input-group-append:not(:last-child) > .input-group-text,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .btn,
.input-group.has-validation > .input-group-append:nth-last-child(n + 3) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.5rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 1px solid #dee2e6; }
  .nav-tabs .nav-link {
    margin-bottom: -1px;
    border: 1px solid transparent;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #e9ecef #e9ecef #dee2e6; }
    .nav-tabs .nav-link.disabled {
      color: #6c757d;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #495057;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.25rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff; }

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-xs, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: 50% / 100% 100% no-repeat; }

.navbar-nav-scroll {
  max-height: 75vh;
  overflow-y: auto; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xs, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-xs, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xs, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-xs, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xs, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-xs, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xs, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-xs, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-nav-scroll {
      overflow: visible; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-xs, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 0.5rem;
      padding-left: 0.5rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-xs, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-nav-scroll {
    overflow: visible; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9); }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7); }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3); }

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9); }

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5); }
  .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9); }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: rgba(0, 0, 0, 0.9); }

.navbar-dark .navbar-brand {
  color: #fff; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: #fff; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.5%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5); }
  .navbar-dark .navbar-text a {
    color: #fff; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: #fff; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.25rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #007bff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0056b3;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #007bff;
  border-color: #007bff; }

.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa; }
  .list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef; }

.list-group-item {
  position: relative;
  display: block;
  padding: 0.75rem 1.25rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125); }
  .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit; }
  .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff; }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0; }
  .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal > .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal > .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl > .list-group-item:first-child {
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl > .list-group-item:last-child {
      border-top-right-radius: 0.25rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl > .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl > .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush {
  border-radius: 0; }
  .list-group-flush > .list-group-item {
    border-width: 0 0 1px; }
    .list-group-flush > .list-group-item:last-child {
      border-bottom-width: 0; }

.list-group-item-secondary {
  color: #000085;
  background-color: #b8b8ff; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #000085;
    background-color: #9f9fff; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #000085;
    border-color: #000085; }

.list-group-item-primary {
  color: #7a1212;
  background-color: #f9c1c1; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #7a1212;
    background-color: #f7aaaa; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #7a1212;
    border-color: #7a1212; }

.list-group-item-primary-dark {
  color: #500000;
  background-color: #e2b8b8; }
  .list-group-item-primary-dark.list-group-item-action:hover, .list-group-item-primary-dark.list-group-item-action:focus {
    color: #500000;
    background-color: #dba6a6; }
  .list-group-item-primary-dark.list-group-item-action.active {
    color: #fff;
    background-color: #500000;
    border-color: #500000; }

.list-group-item-primary-light {
  color: #500000;
  background-color: #e2b8b8; }
  .list-group-item-primary-light.list-group-item-action:hover, .list-group-item-primary-light.list-group-item-action:focus {
    color: #500000;
    background-color: #dba6a6; }
  .list-group-item-primary-light.list-group-item-action.active {
    color: #fff;
    background-color: #500000;
    border-color: #500000; }

.list-group-item-primary-light-hover {
  color: #008500;
  background-color: #b8ffb8; }
  .list-group-item-primary-light-hover.list-group-item-action:hover, .list-group-item-primary-light-hover.list-group-item-action:focus {
    color: #008500;
    background-color: #9fff9f; }
  .list-group-item-primary-light-hover.list-group-item-action.active {
    color: #fff;
    background-color: #008500;
    border-color: #008500; }

.list-group-item-btn-primary-text-hover {
  color: #858585;
  background-color: white; }
  .list-group-item-btn-primary-text-hover.list-group-item-action:hover, .list-group-item-btn-primary-text-hover.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-btn-primary-text-hover.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-btn-primary-text {
  color: #111315;
  background-color: #c1c2c3; }
  .list-group-item-btn-primary-text.list-group-item-action:hover, .list-group-item-btn-primary-text.list-group-item-action:focus {
    color: #111315;
    background-color: #b4b5b6; }
  .list-group-item-btn-primary-text.list-group-item-action.active {
    color: #fff;
    background-color: #111315;
    border-color: #111315; }

.list-group-item-btn-primary-bg {
  color: #855501;
  background-color: #ffe6b8; }
  .list-group-item-btn-primary-bg.list-group-item-action:hover, .list-group-item-btn-primary-bg.list-group-item-action:focus {
    color: #855501;
    background-color: #ffdd9f; }
  .list-group-item-btn-primary-bg.list-group-item-action.active {
    color: #fff;
    background-color: #855501;
    border-color: #855501; }

.list-group-item-body-bg-hover {
  color: #747474;
  background-color: #f6f6f6; }
  .list-group-item-body-bg-hover.list-group-item-action:hover, .list-group-item-body-bg-hover.list-group-item-action:focus {
    color: #747474;
    background-color: #e9e9e9; }
  .list-group-item-body-bg-hover.list-group-item-action.active {
    color: #fff;
    background-color: #747474;
    border-color: #747474; }

.close {
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: .5; }
  .close:hover {
    color: #000;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0; }

a.close.disabled {
  pointer-events: none; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    height: min-content;
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1rem 1rem;
    margin: -1rem -1rem -1rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.5; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem);
      height: min-content; }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto Condensed", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: 0.5rem;
    margin: 0 0.3rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 0.5rem; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: calc(-0.5rem - 1px); }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 0.5rem 0.5rem 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 0.5rem; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 0.5rem 0.5rem 0.5rem 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 0.5rem; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: calc(-0.5rem - 1px); }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 0.5rem 0.5rem 0.5rem;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -0.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 0.5rem; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: calc(-0.5rem - 1px);
    width: 0.5rem;
    height: 1rem;
    margin: 0.3rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: rgba(0, 0, 0, 0.25); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 0.5rem 0 0.5rem 0.5rem;
      border-left-color: #fff; }

.popover-header {
  padding: 0.5rem 0.75rem;
  margin-bottom: 0;
  font-size: 1.3rem !important;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.5rem 0.75rem;
  color: #212529; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-secondary {
  background-color: #0000ff !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #0000cc !important; }

.bg-primary {
  background-color: #eb2323 !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #c91212 !important; }

.bg-primary-dark {
  background-color: #990000 !important; }

a.bg-primary-dark:hover, a.bg-primary-dark:focus,
button.bg-primary-dark:hover,
button.bg-primary-dark:focus {
  background-color: #660000 !important; }

.bg-primary-light {
  background-color: #990000 !important; }

a.bg-primary-light:hover, a.bg-primary-light:focus,
button.bg-primary-light:hover,
button.bg-primary-light:focus {
  background-color: #660000 !important; }

.bg-primary-light-hover {
  background-color: #00ff00 !important; }

a.bg-primary-light-hover:hover, a.bg-primary-light-hover:focus,
button.bg-primary-light-hover:hover,
button.bg-primary-light-hover:focus {
  background-color: #00cc00 !important; }

.bg-btn-primary-text-hover {
  background-color: #fff !important; }

a.bg-btn-primary-text-hover:hover, a.bg-btn-primary-text-hover:focus,
button.bg-btn-primary-text-hover:hover,
button.bg-btn-primary-text-hover:focus {
  background-color: #e6e6e6 !important; }

.bg-btn-primary-text {
  background-color: #212529 !important; }

a.bg-btn-primary-text:hover, a.bg-btn-primary-text:focus,
button.bg-btn-primary-text:hover,
button.bg-btn-primary-text:focus {
  background-color: #0a0c0d !important; }

.bg-btn-primary-bg {
  background-color: #ffa401 !important; }

a.bg-btn-primary-bg:hover, a.bg-btn-primary-bg:focus,
button.bg-btn-primary-bg:hover,
button.bg-btn-primary-bg:focus {
  background-color: #cd8400 !important; }

.bg-body-bg-hover {
  background-color: #e0e0e0 !important; }

a.bg-body-bg-hover:hover, a.bg-body-bg-hover:focus,
button.bg-body-bg-hover:hover,
button.bg-body-bg-hover:focus {
  background-color: #c7c7c7 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-secondary {
  border-color: #0000ff !important; }

.border-primary {
  border-color: #eb2323 !important; }

.border-primary-dark {
  border-color: #990000 !important; }

.border-primary-light {
  border-color: #990000 !important; }

.border-primary-light-hover {
  border-color: #00ff00 !important; }

.border-btn-primary-text-hover {
  border-color: #fff !important; }

.border-btn-primary-text {
  border-color: #212529 !important; }

.border-btn-primary-bg {
  border-color: #ffa401 !important; }

.border-body-bg-hover {
  border-color: #e0e0e0 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  user-select: all !important; }

.user-select-auto {
  user-select: auto !important; }

.user-select-none {
  user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-secondary {
  color: #0000ff !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #0000b3 !important; }

.text-primary {
  color: #eb2323 !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #b11010 !important; }

.text-primary-dark {
  color: #990000 !important; }

a.text-primary-dark:hover, a.text-primary-dark:focus {
  color: #4d0000 !important; }

.text-primary-light {
  color: #990000 !important; }

a.text-primary-light:hover, a.text-primary-light:focus {
  color: #4d0000 !important; }

.text-primary-light-hover {
  color: #00ff00 !important; }

a.text-primary-light-hover:hover, a.text-primary-light-hover:focus {
  color: #00b300 !important; }

.text-btn-primary-text-hover {
  color: #fff !important; }

a.text-btn-primary-text-hover:hover, a.text-btn-primary-text-hover:focus {
  color: #d9d9d9 !important; }

.text-btn-primary-text {
  color: #212529 !important; }

a.text-btn-primary-text:hover, a.text-btn-primary-text:focus {
  color: black !important; }

.text-btn-primary-bg {
  color: #ffa401 !important; }

a.text-btn-primary-bg:hover, a.text-btn-primary-bg:focus {
  color: #b47300 !important; }

.text-body-bg-hover {
  color: #e0e0e0 !important; }

a.text-body-bg-hover:hover, a.text-body-bg-hover:focus {
  color: #bababa !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: #dee2e6; }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6; } }

.bgColor-transparent {
  background: transparent; }

.bgColor-white {
  background: #fff; }

.grid-shadow-left, .grid-shadow-right, .grid-shadow-complet {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: hidden;
  z-index: 9999;
  height: 150px;
  pointer-events: none !important;
  mix-blend-mode: multiply; }
  .grid-shadow-left .shd-box, .grid-shadow-right .shd-box, .grid-shadow-complet .shd-box {
    width: 100%;
    height: 150px; }
  .grid-shadow-left .shd-box:before, .grid-shadow-right .shd-box:before, .grid-shadow-complet .shd-box:before {
    content: " ";
    box-shadow: 0 25px 35px rgba(100, 100, 100, 0.8);
    width: 100%;
    position: absolute;
    height: 150px;
    mix-blend-mode: multiply; }

.grid-shadow-left .shd-box:before {
  transform: rotate(-2deg);
  top: -100%;
  left: -10%;
  transform-origin: bottom left; }

.grid-shadow-right .shd-box:before {
  transform: rotate(2deg);
  top: -100%;
  right: -10%;
  transform-origin: bottom right; }

.grid-shadow-complet .shd-box:before {
  transform: rotate(0deg);
  top: -100%;
  right: 0px;
  transform-origin: bottom right; }

.align-lt {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start; }

.align-lb {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end; }

.align-rt {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; }

.align-rb {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end; }

.align-cc {
  display: flex;
  justify-content: center;
  align-items: center; }

.align-cr {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.align-cl {
  display: flex;
  justify-content: flex-start;
  align-items: center; }

.width-full {
  display: flex;
  width: 100%; }

.width-flull > div {
  flex: 1; }

.bgColor-white .bgColor-none .tinyMCE {
  color: #000000 !important; }
  .bgColor-white .bgColor-none .tinyMCE a {
    color: #000000 !important; }

::selection {
  text-shadow: none;
  background: rgba(19, 49, 87, 0.7);
  color: #eee; }

a {
  color: #000000;
  transition: color 0.3s;
  font-weight: bold; }

a:hover {
  color: #f07d00 !important;
  text-decoration: none; }

h1 {
  margin-top: 35px;
  text-align: center;
  color: #f07d00;
  text-transform: none;
  font-size: 45px;
  font-family: "Eurostile";
  font-weight: 400;
  margin-bottom: 0px;
  width: 100%; }

h2 {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 10px;
  color: #565656;
  font-size: 25px;
  font-family: "Eurostile";
  font-weight: 400;
  width: 100%; }

h3 {
  font-size: 1.7rem; }

html {
  text-rendering: optimizeLegibility;
  font-kerning: normal;
  font-feature-settings: "kern"; }

body {
  overflow-x: hidden;
  overscroll-behavior: none;
  text-rendering: optimizeLegibility; }
  body header {
    position: sticky;
    height: 125px;
    width: 100%;
    background: #9bb600; }
    body header .header__top {
      height: 100px; }
  body main {
    z-index: 50;
    flex: 1;
    width: 100%;
    background: #ffffff;
    position: relative; }
  body footer {
    z-index: 60;
    background: transparent;
    padding: 0px;
    margin: 0px;
    position: relative;
    width: 100%;
    min-height: 50px; }
    body footer .container-fluid, body footer .container-xs, body footer .container-sm, body footer .container-md, body footer .container-lg, body footer .container-xl {
      overflow: visible !important; }
    body footer .schule-fuer-alle {
      height: 40px;
      position: absolute;
      top: 90px;
      left: 50%;
      transform: translateX(-50%); }
      body footer .schule-fuer-alle img, body footer .schule-fuer-alle svg {
        height: 100%;
        width: 100%;
        margin-left: 110px; }
    body footer .footer-kopf {
      width: 100%;
      position: relative;
      height: 100px;
      z-index: 99999999; }
      body footer .footer-kopf img {
        width: 250px;
        left: 50%;
        position: relative;
        margin-top: -8px;
        z-index: 9999;
        transform: translateX(-50%); }
    body footer .kontakticons {
      max-width: 230px;
      margin-left: auto;
      margin-right: auto; }
      body footer .kontakticons .text {
        display: none; }
      body footer .kontakticons .fontawsome-icon svg {
        color: #fff; }
    body footer .site-map {
      display: flex;
      justify-content: space-evenly;
      width: 100%; }
      body footer .site-map li {
        width: calc(100% / 4);
        text-align: center;
        padding-top: 40px;
        padding-bottom: 40px; }
        body footer .site-map li h5 a {
          color: #fff;
          font-weight: 400;
          font-size: 25px; }
        body footer .site-map li .site-map--lev2 {
          width: 100%; }
          body footer .site-map li .site-map--lev2 li {
            padding-top: 10px;
            padding-bottom: 0px;
            width: 100%; }
            body footer .site-map li .site-map--lev2 li a {
              font-size: 12px;
              font-weight: normal;
              text-transform: none;
              color: #ffffff; }
    body footer .footer {
      text-align: center;
      position: relative;
      padding-right: 15px;
      padding-left: 15px;
      color: #6ebce2;
      padding-top: 30px;
      padding-bottom: 30px;
      background: #0886c3;
      border-bottom: 20px solid #ff00ff;
      overflow: hidden; }
      body footer .footer a {
        color: #6ebce2;
        font-weight: normal; }
      body footer .footer a:hover {
        color: #565656 !important; }
      body footer .footer h2 {
        font-synthesis-weight: none !important; }
  body .btn-primary {
    color: #212529; }

.grid-container {
  position: relative; }
  .grid-container .content--shadow {
    position: absolute;
    top: 0px;
    width: 100%; }
  .grid-container .content--shadow:before {
    content: "";
    position: absolute;
    top: 80%;
    left: 0.35714rem;
    bottom: 1.07143rem;
    width: 50%;
    max-width: 21.42857rem;
    background: rgba(0, 0, 0, 0.2);
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.2);
    z-index: -1; }
  .grid-container .container-fluid-shadow {
    overflow: hidden;
    position: relative;
    z-index: 3; }

.shd-box-main {
  position: absolute !important;
  top: 0px;
  left: 0px;
  width: 100%;
  overflow: hidden;
  z-index: 9999;
  height: 150px;
  pointer-events: none !important;
  mix-blend-mode: multiply; }
  .shd-box-main .shd-box {
    width: 100%;
    height: 150px; }
  .shd-box-main .shd-box:before {
    content: " ";
    box-shadow: 0 25px 35px rgba(100, 100, 100, 0.8);
    transform: rotate(-2deg);
    top: -100%;
    left: -10%;
    width: 100%;
    position: absolute;
    height: 150px;
    mix-blend-mode: multiply;
    transform-origin: bottom left; }

body #teaservideo {
  width: 100%;
  min-height: unset !important;
  margin-top: 65px; }

body main .row {
  margin-left: 10px;
  margin-right: 10px;
  width: calc(100% - 20px); }
  body main .row .col-md-12 {
    padding-right: 0px !important;
    padding-left: 0px !important; }
  body main .row .infobox {
    margin-left: 0px !important;
    margin-right: 0px !important; }

body main .container-fluid .teaser, body main .container-xs .teaser, body main .container-sm .teaser, body main .container-md .teaser, body main .container-lg .teaser, body main .container-xl .teaser {
  height: calc(100vw / 16 * 9); }

body footer .kontakticons a .fontawsome-icon {
  width: 70px; }

body footer .kontakticons a i {
  padding: 0px 10px 10px; }
  body footer .kontakticons a i img, body footer .kontakticons a i svg {
    height: 50px; }

@media (max-width: 1199.98px) {
  body #teaservideo {
    width: 100%;
    min-height: unset !important;
    margin-top: 65px; }
  body main .row {
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100% - 20px); }
    body main .row .col-md-12 {
      padding-right: 0px !important;
      padding-left: 0px !important; }
    body main .row .infobox {
      margin-left: 0px !important;
      margin-right: 0px !important; }
  body main .cat-listing {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%; }
  body main .container-fluid .teaser, body main .container-xs .teaser, body main .container-sm .teaser, body main .container-md .teaser, body main .container-lg .teaser, body main .container-xl .teaser {
    height: calc(100vw / 16 * 9); } }

@media (max-width: 1199.98px) and (min-width: 576px) {
  html #teaserBilder .teaser--bg {
    top: 100px !important; }
  html body header #header-icon {
    width: 135px; }
    html body header #header-icon a {
      margin-right: 10px; }
      html body header #header-icon a i img, html body header #header-icon a i svg {
        height: 30px; }
    html body header #header-icon a:last-child {
      margin-right: 0px !important; }
  html body h1 {
    font-size: 40px !important; }
  html body h2 {
    font-size: 22px !important; }
  html body h3 {
    font-size: 20px !important; }
  html body #navigation {
    height: 130px; }
    html body #navigation canvas {
      margin-top: 20px; }
  html body main #teaVideo {
    min-height: calc(100vw / 16 * 9 - 150px) !important; } }

@media (max-width: 1199.98px) {
  .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12 {
    padding-right: 5px !important;
    padding-left: 5px !important; }
  .pb-4 {
    padding-bottom: 10px !important; }
  body {
    font-size: 1rem !important; }
    body header .headerlogo picture img {
      max-height: 160px !important; }
    body header .logo-left {
      height: 40px !important;
      z-index: 9999; }
      body header .logo-left img {
        max-width: 50px !important; }
    body header .logo-right {
      z-index: 9999; }
      body header .logo-right img, body header .logo-right svg {
        height: 40px !important; }
    body main {
      padding-top: 130px !important; }
      body main .teaser article h1 {
        font-size: 50px !important; }
      body main .grid-shadow-left .shd-box:before {
        box-shadow: 0 15px 25px rgba(100, 100, 100, 0.8);
        width: 110%; }
      body main .parallax-bilder {
        height: 290px !important;
        min-height: 290px !important; }
        body main .parallax-bilder picture img {
          width: unset !important;
          min-width: 100% !important;
          position: absolute; }
      body main .infobox {
        margin-left: -20px !important;
        margin-right: -20px !important; }
      body main .parallax-bilder-fixed picture img {
        min-width: 100% !important;
        min-height: 100% !important; }
      body main .tinyMCEbackground-3 {
        padding: 15px; }
        body main .tinyMCEbackground-3 p {
          font-size: 15px !important; }
      body main hr {
        margin-top: 5px; }
      body main h4 {
        font-size: 22px !important; }
      body main .buttons-modul .btn-modul i {
        width: 90px;
        height: 90px;
        padding: 15px !important; }
    body footer {
      width: 100% !important;
      min-height: 400px !important; }
      body footer .ilu {
        z-index: 1; }
      body footer .grid-container {
        z-index: 999; }
      body footer .kontakticons a .fontawsome-icon {
        width: 70px; }
      body footer .kontakticons a i {
        padding: 0px 10px 10px; }
        body footer .kontakticons a i img, body footer .kontakticons a i svg {
          height: 50px; } }

@media (max-width: 767.98px) {
  html {
    overflow-x: hidden;
    width: 100vw; }
  body h1 {
    line-height: normal; }
  body main {
    padding-top: 0px !important;
    margin-top: 0px !important; }
    body main .scrollblock .scrollblock-chapter-content {
      width: 100vw !important;
      left: 0px !important;
      top: 65% !important;
      padding-left: 15px;
      padding-right: 15px; }
    body main .scrollblock .scrollblock-avatar {
      display: none !important; }
    body main .scrollblock .scrollblock-chapter-logo {
      position: absolute !important;
      top: -20px !important;
      width: 220vw !important; }
      body main .scrollblock .scrollblock-chapter-logo picture img {
        width: 220vw !important; }
    body main .scrollblock .scrollblock-chapter-content-no-logo {
      top: 40% !important; }
  body #header-dynamic-background {
    min-height: 120px !important;
    max-height: 120px !important;
    height: 120px !important;
    display: none !important; }
  body header #header-icon {
    top: 100px;
    height: 35px;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 5px;
    padding-right: 10px;
    width: 165px; }
    body header #header-icon a {
      margin-right: 5px; }
      body header #header-icon a i img, body header #header-icon a i svg {
        height: 50px; }
    body header #header-icon a:last-child {
      margin-right: 0px !important; }
  body header .shd-box:before {
    top: -106% !important;
    transform: rotate(-3deg) !important; }
  body #header-silhouette, body header {
    height: 95px !important; }
  body #header-silhouette img, body #header-silhouette svg {
    height: 95px !important; }
  body #header-silhouette .schule-fuer-alle {
    top: 75px !important;
    left: 50px !important;
    width: 130px !important;
    height: 30px !important; }
    body #header-silhouette .schule-fuer-alle img, body #header-silhouette .schule-fuer-alle svg {
      height: 100% !important; }
  body #teaserBilder .teaser--bg {
    top: 100px !important; }
    body #teaserBilder .teaser--bg picture img {
      max-height: 280px !important;
      min-height: unset !important;
      min-height: unset !important; }
  body #navigation {
    width: 100vw !important;
    height: 100px !important; }
    body #navigation nav #navbarSupportedContent {
      overflow: scroll; }
      body #navigation nav #navbarSupportedContent .navbar-nav .nav-item .nav-link {
        height: 40px !important;
        font-size: 1.2rem !important; }
      body #navigation nav #navbarSupportedContent .navbar-nav .nav-item .dropdown-menu .dropdown-menu-list .dropdown-item {
        font-size: 1rem !important; }
      body #navigation nav #navbarSupportedContent .shd-box-main {
        display: none !important; }
    body #navigation #scrollleiste {
      bottom: calc(100% - 2px);
      height: 2px; }
    body #navigation .navbar-toggler {
      top: 50%;
      transform: translateY(-50%);
      display: block;
      right: 0px !important;
      top: 38px !important; }
    body #navigation .shd-box-main .shd-box:before {
      box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important;
      transform: rotate(-2deg) !important; }
    body #navigation #logo {
      top: 0px; }
      body #navigation #logo a {
        left: 50%;
        transform: translateX(-50%) !important;
        height: 110%; }
      body #navigation #logo .header-logo {
        height: 80% !important;
        top: 5px !important; }
      body #navigation #logo .logo-subheadline {
        width: 100%;
        left: 15px;
        transform: none !important;
        top: 50px;
        text-align: left;
        font-size: 10px;
        color: #333333 !important; }
      body #navigation #logo #nav-devider {
        top: 25px;
        height: calc(100% - 35px);
        width: calc(100vw - 30px); }
        body #navigation #logo #nav-devider .nav-top-icons a i img, body #navigation #logo #nav-devider .nav-top-icons a i svg {
          height: 30px; }
      body #navigation #logo .show .navbar-nav {
        opacity: 1 !important; }
      body #navigation #logo .navbar-nav {
        height: auto !important;
        opacity: 0 !important;
        flex-direction: column !important; }
        body #navigation #logo .navbar-nav .nav-item {
          flex-direction: column; }
          body #navigation #logo .navbar-nav .nav-item .dropdown-menu .dropdown-item {
            margin-left: auto;
            margin-right: auto;
            transform: unset !important; }
        body #navigation #logo .navbar-nav .nav-link {
          left: unset !important;
          transform: none !important;
          margin-left: auto;
          margin-right: auto; }
  body main #teaVideo {
    min-height: 120px !important; }
  body main #teaVideo:before {
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important; }
  body main .shd-box-main .shd-box:before {
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important;
    transform: rotate(5deg) !important; }
  body main .container-fluid > .row, body main .container-xs > .row, body main .container-sm > .row, body main .container-md > .row, body main .container-lg > .row, body main .container-xl > .row {
    margin: 0px !important;
    width: calc(100%); }
  body main .kontakticons {
    display: flex; }
    body main .kontakticons a {
      margin-bottom: 40px !important; }
      body main .kontakticons a i {
        justify-content: center;
        display: flex; }
  body main .tinyMCE {
    padding: 0px 10px !important; }
  body main .parallax-gallery {
    height: calc(100vw / 4 * 5) !important;
    width: calc(100% + 40px) !important;
    min-height: 150px !important; }
    body main .parallax-gallery .parallax-gallery-inner .gallery-pic {
      background-attachment: scroll;
      width: 102vw !important; }
    body main .parallax-gallery .parallax-gallery-inner .gallery-pic:before {
      position: fixed;
      content: ' ';
      display: block;
      z-index: -10; }
  body footer {
    padding-bottom: 0px !important; }
    body footer .schule-fuer-alle {
      width: 130px !important;
      height: 30px !important;
      margin-left: -50px; }
      body footer .schule-fuer-alle img, body footer .schule-fuer-alle svg {
        height: 100% !important; }
    body footer .shd-box-main .shd-box:before {
      box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important;
      transform: rotate(-2deg) !important; }
    body footer h2 {
      font-size: 30px !important;
      font-weight: 400 !important;
      font-synthesis-weight: none !important; }
    body footer .comp_info {
      float: unset !important;
      margin-right: 0px !important;
      margin-left: 0px !important;
      margin-bottom: 50px !important; }
      body footer .comp_info .comp1-data {
        width: 100%;
        display: flex;
        flex-direction: column; }
        body footer .comp_info .comp1-data .comp1-name h2 {
          text-align: center; }
    body footer .footer .shd-box-main .shd-box {
      height: 80px; }
    body footer .footer .shd-box-main .shd-box:before {
      box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.8) !important;
      transform: rotate(-2deg) !important; } }

@media (max-width: 991.98px) {
  .parallax-bilder {
    margin-top: 5px;
    margin-bottom: 5px; }
  #teaserBilder .teaser--bg {
    top: 100px !important; }
  body #navigation .shd-box-main {
    z-index: 1; }
    body #navigation .shd-box-main .shd-box:before {
      left: 0px !important; }
  body main h1 {
    font-size: 45px !important; }
  body main h2 {
    font-size: 20px !important; } }

@media (max-width: 991.98px) and (max-width: 767.98px) {
  body #header {
    height: 50px !important;
    max-width: 100vw !important; }
    body #header .header__top {
      height: 50px !important; }
    body #header #headerlogo {
      width: 100%; }
      body #header #headerlogo img {
        height: 33.65px !important;
        width: 375px !important; }
  body .bildergalerie--article {
    width: 100vw !important;
    height: 100vh !important;
    overflow: hidden; }
    body .bildergalerie--article .teaser--bg {
      max-width: 100vw !important;
      max-height: 100vh !important; }
  body .teaser--main .teaser {
    min-height: 280px !important;
    max-height: 450px !important; } }

@media (max-width: 991.98px) {
  body footer {
    min-height: 520px !important;
    padding-bottom: 0px !important; }
    body footer .oeffnungszeitenFooter {
      max-width: 100%;
      margin-left: 0px; }
      body footer .oeffnungszeitenFooter .row {
        max-width: 100% !important;
        width: 100%;
        margin-left: auto;
        margin-right: auto; } }
