$downloads--color: #000 !default;
$downloads--hover-color: rgba(0,0,0,0.5) !default;

.downloads {
  .download {
    margin-bottom: 40px;
    .link-text{
      height: 50px;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
  }
  a {
    color: $downloads--color;
    transition: all 0.3s;
    img,svg {
      color: $downloads--color;
      fill: $downloads--color;
      transition: all 0.3s;
      height: 50px;
      width: 50px;
    }
  }

  a:hover {
    color: $downloads--hover-color;
    img,svg {
      color: $downloads--hover-color;
      fill: $downloads--hover-color;
    }
  }
}