.parallax-gallery {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 15px);
  height: 1080px !important;
  background: #ffffff;
  overflow: hidden;
  position: relative;
  .parallax-gallery-inner  {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .jarallax-img {
      will-change: transform;
      img {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .active {
      opacity: 1 !important;
    }
  }
  .jarallax {
    position: relative;
    z-index: 0;
  }
  .jarallax .jarallax-img {
    position: absolute;
    object-fit: cover;
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    //font-family: 'object-fit: cover;';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    img {
      min-width: 100%;
      min-height: 100%;
    }
  }

  .teaser-texte {
    position: absolute;
    top: 40%;
    transform: translate(-50px, -50%);
    margin-top: 100px;
    left: 50px;
    opacity: 0;
    font-size: 300px;
    line-height: 230px;
    color: #fff;
    animation: aniTeaserText 2s forwards;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
  }


  @keyframes aniTeaserText {
    0% {
      opacity: 0;
      transform: translate(-50px, -50%);
    }
    100% {
      opacity: 1;
      transform: translate(0px, -50%);
    }
  }

}
.parallax-type-gallery {
  max-height: 450px !important;
}

@include media-breakpoint-down(lg) {
  @include media-breakpoint-up(sm) {
    .parallax-gallery {
      height: 600px !important;
      .teaser-texte {
        font-size: 180px;
        line-height: 135px;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .parallax-gallery {
    .teaser-texte {
      font-size: 100px;
      line-height: 90px;
    }
  }
  .parallax-type-gallery {
    .teaser-texte {
      font-size: 80px;
      line-height: 70px;
      top: 30%;
    }
  }
}